var Handlebars = require("handlebars-wrapper/partials");module.exports = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"row p-y-05 nojs-show\"><div class=\"col-xs-12 offset-md-3 col-md-6\"><div class=\"alert alert-"
    + alias2(alias1((depth0 != null ? depth0.alert_type : depth0), depth0))
    + "\" role=\"alert\">"
    + alias2(alias1((depth0 != null ? depth0.message : depth0), depth0))
    + "</div></div></div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"container\">"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.messages : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<form method=\"post\" action=\"/email_confirmation\"><div class=\"row p-y-05\"><div class=\"col-xs-12 offset-md-3 col-md-6\"><label for=\"code\">Confirmation code:</label><input type=\"text\" class=\"form-control\" placeholder=\"Confirmation code\" name=\"code\"> <small class=\"text-muted\">Confirmation code from the e-mail you've received.</small></div></div><div class=\"row p-y-05\"><div class=\"col-xs-6 offset-md-3 col-md-6 text-xs-right\"><button class=\"btn btn-success\" type=\"submit\">Submit</button></div></div></form></div>";
},"useData":true});