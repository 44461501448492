var Handlebars = require("handlebars-wrapper/partials");module.exports = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.sub_items : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + " ";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.lambda, alias3=container.escapeExpression;

  return "<li "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\" data-taunus-ignore=\"true\" href=\""
    + alias3(alias2((depth0 != null ? depth0.link : depth0), depth0))
    + "\" role=\"button\" aria-haspopup=\"true\" aria-expanded=\"false\" id=\"nav-sub-item-"
    + alias3(alias2((data && data.index), depth0))
    + "\">"
    + alias3(alias2((depth0 != null ? depth0.title : depth0), depth0))
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</a><div class=\"dropdown-menu dropdown-menu-right\" aria-labelledby=\"nav-sub-item-"
    + alias3(alias2((data && data.index), depth0))
    + "\">"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.sub_items : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div></li>";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"nav-item dropdown active\" ";
},"5":function(container,depth0,helpers,partials,data) {
    return "class=\"nav-item dropdown\"";
},"7":function(container,depth0,helpers,partials,data) {
    return "<span class=\"sr-only\">(current section)</span>";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.divide : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + " ";
},"10":function(container,depth0,helpers,partials,data) {
    return "<div class=\"dropdown-divider\"></div>";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " <a class=\"dropdown-item active\" href=\""
    + alias2(alias1((depth0 != null ? depth0.link : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + " <span class=\"sr-only\">(current page)</span></a> ";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " <a class=\"dropdown-item\" href=\""
    + alias2(alias1((depth0 != null ? depth0.link : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</a> ";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data})) != null ? stack1 : "")
    + " ";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<li class=\"nav-item active\"><a class=\"nav-link\" href=\""
    + alias2(alias1((depth0 != null ? depth0.link : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + " <span class=\"sr-only\">(current)</span></a></li>";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<li class=\"nav-item\"><a class=\"nav-link\" href=\""
    + alias2(alias1((depth0 != null ? depth0.link : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</a></li>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<nav class=\"navbar navbar-light bg-faded\"><div class=\"container\"><button class=\"navbar-toggler hidden-md-up\" type=\"button\" data-toggle=\"collapse\" data-target=\"#expanded-top-navbar\"><i class=\"fa fa-bars\"></i> [not] AST catalog</button><div class=\"collapse navbar-toggleable-sm\" id=\"expanded-top-navbar\"><div class=\"row\"><div class=\"col-xs-12 col-sm-4 push-sm-8 col-md-6 push-md-6\"><div class=\"pull-xs-right\"><ul class=\"nav navbar-nav\">"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.menu_items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul></div></div><div class=\"col-xs-12 col-sm-8 pull-sm-4 col-md-6 pull-md-6\"><form method=\"get\" action=\"/search\" class=\"song-search-form\"><div class=\"input-group\"><input type=\"text\" class=\"form-control\" placeholder=\"Search\" name=\"search\" value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.search : depth0), depth0))
    + "\"> <span class=\"input-group-btn\"><button class=\"btn btn-outline-success\" type=\"submit\">Search</button></span></div></form></div></div></div></div></nav>";
},"useData":true});