var Handlebars = require("handlebars-wrapper/partials");module.exports = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"row p-y-1 nojs-show\"><div class=\"col-xs-12 offset-md-3 col-md-6\"><div class=\"alert alert-"
    + alias2(alias1((depth0 != null ? depth0.alert_type : depth0), depth0))
    + "\" role=\"alert\">"
    + alias2(alias1((depth0 != null ? depth0.message : depth0), depth0))
    + "</div></div></div>";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(helpers.lookup.call(depth0 != null ? depth0 : {},depth0,"result_type",{"name":"lookup","hash":{},"data":data}),depth0,{"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " ";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(partials.song_card,depth0,{"name":"song_card","hash":{"_csrf":(depths[1] != null ? depths[1]._csrf : depths[1]),"reorder_allowed":(depths[1] != null ? depths[1].reorder_allowed : depths[1]),"offline":(depths[1] != null ? depths[1].offline : depths[1]),"viewer":(depths[1] != null ? depths[1].viewer : depths[1])},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " ";
},"7":function(container,depth0,helpers,partials,data) {
    return "<div class=\"card text-xs-center\"><div class=\"card-header\"><div class=\"row\"><div class=\"col-xs-12\"><strong>No songs found.</strong></div></div></div><div class=\"card-block\"><div class=\"row\"><div class=\"col-xs-12\">Try searching for something else.</div></div></div></div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"container\">"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.messages : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.result_type : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div id=\"song-accordion\" role=\"tablist\" aria-multiselectable=\"false\">"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.songs : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</div>"
    + ((stack1 = container.invokePartial(partials.pagination,(depth0 != null ? depth0.pages : depth0),{"name":"pagination","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true,"useDepths":true});