'use strict';

var _isoLangs = {
		"ab":{ "name":"Abkhaz" },
		"aa":{ "name":"Afar" },
		"af":{ "name":"Afrikaans" },
		"ak":{ "name":"Akan" },
		"sq":{ "name":"Albanian" },
		"am":{ "name":"Amharic" },
		"ar":{ "name":"Arabic" },
		"an":{ "name":"Aragonese" },
		"hy":{ "name":"Armenian" },
		"as":{ "name":"Assamese" },
		"av":{ "name":"Avaric" },
		"ae":{ "name":"Avestan" },
		"ay":{ "name":"Aymara" },
		"az":{ "name":"Azerbaijani" },
		"bm":{ "name":"Bambara" },
		"ba":{ "name":"Bashkir" },
		"eu":{ "name":"Basque" },
		"be":{ "name":"Belarusian" },
		"bn":{ "name":"Bengali" },
		"bh":{ "name":"Bihari" },
		"bi":{ "name":"Bislama" },
		"bs":{ "name":"Bosnian" },
		"br":{ "name":"Breton" },
		"bg":{ "name":"Bulgarian" },
		"my":{ "name":"Burmese" },
		"ca":{ "name":"Catalan" },
		"ch":{ "name":"Chamorro" },
		"ce":{ "name":"Chechen" },
		"ny":{ "name":"Nyanja" },
		"zh":{ "name":"Chinese" },
		"cv":{ "name":"Chuvash" },
		"kw":{ "name":"Cornish" },
		"co":{ "name":"Corsican" },
		"cr":{ "name":"Cree" },
		"hr":{ "name":"Croatian" },
		"cs":{ "name":"Czech" },
		"da":{ "name":"Danish" },
		"dv":{ "name":"Divehi" },
		"nl":{ "name":"Dutch" },
		"en":{ "name":"English" },
		"eo":{ "name":"Esperanto" },
		"et":{ "name":"Estonian" },
		"ee":{ "name":"Ewe" },
		"fo":{ "name":"Faroese" },
		"fj":{ "name":"Fijian" },
		"fi":{ "name":"Finnish" },
		"fr":{ "name":"French" },
		"ff":{ "name":"Fula" },
		"gl":{ "name":"Galician" },
		"ka":{ "name":"Georgian" },
		"de":{ "name":"German" },
		"el":{ "name":"Greek" },
		"gn":{ "name":"Guarani" },
		"gu":{ "name":"Gujarati" },
		"ht":{ "name":"Haitian" },
		"ha":{ "name":"Hausa" },
		"he":{ "name":"Hebrew" },
		"hz":{ "name":"Herero" },
		"hi":{ "name":"Hindi" },
		"ho":{ "name":"Hiri Motu" },
		"hu":{ "name":"Hungarian" },
		"ia":{ "name":"Interlingua" },
		"id":{ "name":"Indonesian" },
		"ie":{ "name":"Interlingue" },
		"ga":{ "name":"Irish" },
		"ig":{ "name":"Igbo" },
		"ik":{ "name":"Inupiaq" },
		"io":{ "name":"Ido" },
		"is":{ "name":"Icelandic" },
		"it":{ "name":"Italian" },
		"iu":{ "name":"Inuktitut" },
		"ja":{ "name":"Japanese" },
		"jv":{ "name":"Javanese" },
		"kl":{ "name":"Greenlandic" },
		"kn":{ "name":"Kannada" },
		"kr":{ "name":"Korean" }, // not a correct ISO code (originally was "Kanuri")
		"ks":{ "name":"Kashmiri" },
		"kk":{ "name":"Kazakh" },
		"km":{ "name":"Khmer" },
		"ki":{ "name":"Kikuyu, Gikuyu" },
		"kz":{ "name":"Kazakh" }, // not a correct ISO code
		"rw":{ "name":"Kinyarwanda" },
		"ky":{ "name":"Kyrgyz" },
		"kv":{ "name":"Komi" },
		"kg":{ "name":"Kongo" },
		"ko":{ "name":"Korean" },
		"ku":{ "name":"Kurdish" },
		"kj":{ "name":"Kuanyama" },
		"la":{ "name":"Latin" },
		"lb":{ "name":"Luxembourgish" },
		"lg":{ "name":"Luganda" },
		"li":{ "name":"Limburgish" },
		"ln":{ "name":"Lingala" },
		"lo":{ "name":"Lao" },
		"lt":{ "name":"Lithuanian" },
		"lu":{ "name":"Luba-Katanga" },
		"lv":{ "name":"Latvian" },
		"gv":{ "name":"Manx" },
		"mk":{ "name":"Macedonian" },
		"mg":{ "name":"Malagasy" },
		"ms":{ "name":"Malay" },
		"ml":{ "name":"Malayalam" },
		"mt":{ "name":"Maltese" },
		"mi":{ "name":"Maori" },
		"mr":{ "name":"Marathi" },
		"mh":{ "name":"Marshallese" },
		"mn":{ "name":"Mongolian" },
		"na":{ "name":"Nauru" },
		"nv":{ "name":"Navajo" },
		"nb":{ "name":"Norwegian Bokmal" },
		"nd":{ "name":"North Ndebele" },
		"ne":{ "name":"Nepali" },
		"ng":{ "name":"Ndonga" },
		"nn":{ "name":"Norwegian Nynorsk" },
		"no":{ "name":"Norwegian" },
		"ii":{ "name":"Nuosu" },
		"nr":{ "name":"South Ndebele" },
		"oc":{ "name":"Occitan" },
		"oj":{ "name":"Ojibwe, Ojibwa" },
		"cu":{ "name":"Church Slavic" },
		"om":{ "name":"Oromo" },
		"or":{ "name":"Oriya" },
		"os":{ "name":"Ossetian" },
		"pa":{ "name":"Panjabi" },
		"pi":{ "name":"Pali" },
		"fa":{ "name":"Persian" },
		"pl":{ "name":"Polish" },
		"ps":{ "name":"Pashto" },
		"pt":{ "name":"Portuguese" },
		"qu":{ "name":"Quechua" },
		"rm":{ "name":"Romansh" },
		"rn":{ "name":"Kirundi" },
		"ro":{ "name":"Romanian" },
		"ru":{ "name":"Russian" },
		"sa":{ "name":"Sanskrit" },
		"sc":{ "name":"Sardinian" },
		"sd":{ "name":"Sindhi" },
		"se":{ "name":"Northern Sami" },
		"sm":{ "name":"Samoan" },
		"sg":{ "name":"Sango" },
		"sr":{ "name":"Serbian" },
		"gd":{ "name":"Gaelic" },
		"sn":{ "name":"Shona" },
		"si":{ "name":"Sinhalese" },
		"sk":{ "name":"Slovak" },
		"sl":{ "name":"Slovene" },
		"so":{ "name":"Somali" },
		"st":{ "name":"Southern Sotho" },
		"es":{ "name":"Spanish" },
		"su":{ "name":"Sundanese" },
		"sw":{ "name":"Swahili" },
		"ss":{ "name":"Swati" },
		"sv":{ "name":"Swedish" },
		"ta":{ "name":"Tamil" },
		"te":{ "name":"Telugu" },
		"tg":{ "name":"Tajik" },
		"th":{ "name":"Thai" },
		"ti":{ "name":"Tigrinya" },
		"bo":{ "name":"Tibetan" },
		"tk":{ "name":"Turkmen" },
		"tl":{ "name":"Tagalog" },
		"tn":{ "name":"Tswana" },
		"to":{ "name":"Tonga" },
		"tr":{ "name":"Turkish" },
		"ts":{ "name":"Tsonga" },
		"tt":{ "name":"Tatar" },
		"tw":{ "name":"Twi" },
		"ty":{ "name":"Tahitian" },
		"ug":{ "name":"Uyghur" },
		"uk":{ "name":"Ukrainian" },
		"ur":{ "name":"Urdu" },
		"uz":{ "name":"Uzbek" },
		"ve":{ "name":"Venda" },
		"vi":{ "name":"Vietnamese" },
		"vo":{ "name":"Volapuk" },
		"wa":{ "name":"Walloon" },
		"cy":{ "name":"Welsh" },
		"wo":{ "name":"Wolof" },
		"fy":{ "name":"Western Frisian" },
		"xh":{ "name":"Xhosa" },
		"yi":{ "name":"Yiddish" },
		"yo":{ "name":"Yoruba" },
		"za":{ "name":"Zhuang, Chuang" }
	};

var _genre_masks = [
	{name:'Children',	mask:2		},
	{name:'Patriotic',	mask:4		},
	{name:'Romance',	mask:8		},
	{name:'Chanson',	mask:16		},
	{name:'Folk',		mask:32		},
	{name:'Cinema',		mask:64		},
	{name:'Pop',		mask:128	},
	{name:'Rock',		mask:256	},
	{name:'Rap',		mask:512	},
	{name:'Retro',		mask:1024	},
];

var _months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
var _voices = {1:'male', 2:'female', 3:'duet'};
var _majorTones = [ "C", "Db", "D", "Eb", "E", "F", "F#", "G", "Ab", "A", "Bb", "B" ];
var _minorTones = [ "Am", "Bbm", "Bm", "Cm", "C#m", "Dm", "D#m", "Em", "Fm", "F#m", "Gm", "G#m" ];

function _activate_item( menu_item, action ) {
	menu_item.active = ( menu_item.action == action );

	if( menu_item.sub_items ) {
		for( var sub_index = 0; sub_index < menu_item.sub_items.length; sub_index++ ) {
			menu_item.sub_items[sub_index] = _activate_item( menu_item.sub_items[sub_index], action );

			if( menu_item.sub_items[sub_index].active )
				menu_item.active = true;
		}
	}

	return menu_item;
};

function _extendParameters( parameters, extension ) {
	for( var d in extension )
		if( parameters[d] == null && extension[d] != null )
			parameters[d] = extension[d];

	return parameters;
}

function _getPageLink( parameters, extension ) {
	// TODO: I would really prefer to use jQuery here
	var param = require('jquery-param');

	if( extension != null ) {
		parameters = _extendParameters( parameters, extension );
	}

	if( Object.keys( parameters ).length ) {
		return '?' + param( parameters );
	}

	return '';
};

function _getBootstrapAlertType( type ) {
	switch( type ) {
		case 'success':
		case 'info':
		case 'warning':
		case 'danger':
			return type;
		case 'error':
			return 'danger';
		default:
			return 'info';
	}
}

function _genreMaskToArray( genre_mask, masks, linkify ) {
	if( genre_mask == 0 ) {
		return ['Unknown'];
	}

	var genres = [];

	masks.forEach(function(mask) {
		if( mask.mask & genre_mask ) {
			genres.push( linkify? '<a href="/search?genres='+mask.mask+'">'+mask.name+'</a>': mask.name );
		}
	});

	return genres;
};

function _keyToString( raw_key, preference ) {
	preference = preference || 0;

	if( raw_key >= 12 ) {
		return _majorTones[( raw_key + preference ) % 12]
	} else {
		return _minorTones[( raw_key + preference + 12 ) % 12 ];
	}
}

function _getDateFromRelease( release ) {
	if( !release )
		return null;

	return ''+('0' + Math.floor( release % 100 )).slice(-2)+'.'+Math.floor( release / 100 );
}

function _parseSongForTemplate( song ) {
	if( !song ) return;

	if( !song.artists )
		console.log(song);

	// processing
	var new_song = {};
	new_song.num = song.num;
	new_song.artists_formatted = song.artists.map(function(artist) {return '<a href="/artist/'+encodeURIComponent(artist)+'/songs">'+artist+'</a>'}).join(', ');
	if( 'artist' in song ) {
		new_song.artist = song.artist;
	} else {
		new_song.artist = song.artists.join(' & ');
	}
	new_song.title = song.title;
	if( 'movie' in song )
		new_song.movie = song.movie;

	switch( song.type ) {
		case 1: new_song.type_label = {content: 'mp3', color: 'primary'};  break; // mp3 - blue
		case 2: new_song.type_label = {content: 'midi', color: 'success'}; break; // midi - green
		case 5: new_song.type_label = {content: 'wav', color: 'danger'}; break; // wav - red
		default: new_song.type_label = {content: '?', color: ''};
	}

	if( 'release' in song ) {
		//new_song.release = song.release;
		new_song.release_formatted = '<a href="/search?release='+_getDateFromRelease(song.release)+'">'+_months[Math.abs( Math.floor( song.release % 100 ) - 1 )]+' '+Math.floor( song.release / 100 )+'</a>';
	}

	if( 'voice' in song ) {
		new_song.voice = _voices[song.voice];
	}

	new_song.tempo = song.tempo;

	if( 'key' in song ) {
		new_song.raw_key = song.key;
		new_song.key = _keyToString( song.key );
	}

	if( 'genre' in song ) {
		//new_song.genres = song.genre;
		//new_song.genres = _genreMaskToArray( song.genre, _genre_masks ).join(', ');
		new_song.genres_formatted = _genreMaskToArray( song.genre, _genre_masks, true ).join(', ');
	}

	if( 'duration' in song ) {
		new_song.duration = '' + Math.floor( song.duration / 60 ) + 'm ' + Math.floor( song.duration % 60 ) + 's';
	} else {
		new_song.duration = '';
	}

	if( 'languages' in song ) {
		/*new_song.languages = song.languages.map(function(language_code){
			var lang = _isoLangs[language_code];
			return lang ? lang.name : language_code;
		});*/
		new_song.languages_formatted = song.languages.map(function(language_code){
			var lang = _isoLangs[language_code];
			return '<a href="/search?languages='+language_code+'">'+(lang ? lang.name : language_code)+'</a>';
		}).join(', ');
	}

	if( song.bvocal ) {
		new_song.backvocal_label = { content: '<i class="fa fa-microphone fa-fw" title="Song has back vocal" aria-hidden="true"></i><span class="sr-only">Song has back vocal.</span>', color: 'default' };
	}
	

	if( ( song.num >= 78000 ) && ( song.num <= 99999  ) ) {
		new_song.model_label = { content: 'AST-100', color: 'warning' };
	} else {
		new_song.model_label = { content: 'AST-50',  color: 'success' };
	}

	if( 'position' in song )
		new_song.position = song.position;

	if( 'changes' in song ) {
		new_song.changes = song.changes;
		new_song.changes_count = song.changes.updated + song.changes.deleted;
	}

	if( 'available' in song )
		new_song.available = song.available;

	if( 'new' in song )
		new_song.new = song.new;

	if( 'last_change' in song ) {
		var last_change_date = new Date(song.last_change * 1000);
		new_song.last_change = ('0' + last_change_date.getDate()).slice(-2) + '.' + ('0' + (last_change_date.getMonth()+1)).slice(-2) + '.' + last_change_date.getFullYear();
	}

	// there may be a better way to do this
	if( 'preferences' in song ) {
		if( song.preferences ) {
			new_song.preferences = {};

			if( song.preferences.tempo ) {
				new_song.preferences.tempo = Math.round( ( 1 + 0.04 * song.preferences.tempo ) * song.tempo );
				if( song.preferences.tempo > 0 ) {
					new_song.preferences.tempo_offset = '+' + ( 4 * song.preferences.tempo ) + '%';
				} else {
					new_song.preferences.tempo_offset = '-' + Math.abs( 4 * song.preferences.tempo ) + '%';
				}
			}

			if( song.preferences.key ) {
				new_song.preferences.key = _keyToString( song.key, song.preferences.key );
				if( song.preferences.key > 0 ) {
					new_song.preferences.key_offset = '+' + song.preferences.key;
				} else {
					new_song.preferences.key_offset = '-' + Math.abs(song.preferences.key);
				}
			}
		}
	}

	return new_song;
}

function _parseSquareBrackets( key, val, limit, iteration ) {
	iteration = iteration || 0;
	var result = {};
	var from = key.indexOf("[");
	var to = key.indexOf("]");
	if( from == -1  || to == -1 ) {
		return val;
	} else {
		var index = decodeURIComponent(key.substring(from+1,to));
		key = key.substring(to+1);
		if( iteration < limit ) {
			result[index] = _parseSquareBrackets( key, val, limit, iteration+1 );
		} else {
			result[key] = val;
		}
		return result;
	}
}

function _parseQueryStringPart( qs_part, result ) {
	if(!result) result = {};
	if(!qs_part) return;
	qs_part = qs_part.split("+").join(" "); // replace every + with space, regexp-free version
	var eq = qs_part.indexOf("=");
	var key = eq>-1 ? qs_part.substr(0,eq) : qs_part;
	var val = eq>-1 ? decodeURIComponent(qs_part.substr(eq+1)) : "";
	var from = key.indexOf("[");
	if( from == -1 ) {
		result[decodeURIComponent(key)] = val;
	} else {
		var to = key.indexOf("]");
		var index = decodeURIComponent(key.substring(from+1,to));
		var index_nested = decodeURIComponent(key.substring(from));
		key = decodeURIComponent(key.substring(0,from));

		if(!result[key]) result[key] = [];

		if(!index) {
			result[key].push(val);
		} else {
			result[key] = _parseSquareBrackets( index_nested, val, 5 );
		}
	}
	return result;
}

var offline_mode = 'disabled';

function _performOfflinePlaylistAction( defer, song_num, list_id, action, params ) {
	var getDexie = require('songs_db')( window.localStorage.getItem( 'username' ) );
	var now = ( Date.now() / 1000 ) | 0;

	getDexie.then(function(db) {
		if( !params ) params = {};
		switch( action ) {
			case 'add': {
				db.playlists.where( 'id' ).equals( list_id ).modify(function(playlist) {
					var song_index = 0;
					var found = false;
					while( !found && (song_index < playlist.songs.length ) ) {
						found = ( playlist.songs[song_index].num == song_num );
						if(!found)
							song_index++;
					}

					if( !found ) {
						var new_song = {
							'num': song_num,
							'client_added': now,
							'client_deleted': 0,
						};

						playlist.songs.push( new_song );
					} else {
						if( playlist.songs[song_index]['client_deleted'] ) {
							if( playlist.songs[song_index]['added'] ) {
								playlist.songs[song_index]['client_added'] = 0;
							} else {
								playlist.songs[song_index]['client_added'] = now;
							}
							playlist.songs[song_index]['client_deleted'] = 0;
						}
					}
				}).then(function() {
					defer.resolve({
						'num': song_num,
						'list_id': list_id,
						'action': 'playlist/add',
						'result': 'success'
					});
				}).catch(function(err) {
					defer.reject( null, 'Something happened while trying to modify local playlists.', err );
				});

				break;
			}
			case 'remove': {
				db.playlists.where( 'id' ).equals( list_id ).modify(function(playlist) {
					var new_songs = [];
					for( var i = 0; i < playlist.songs.length; i++ ) {
						if( playlist.songs[i].num == song_num ) {
							if( !playlist.songs[i].client_added ) {
								playlist.songs[i].client_added = 0;
								playlist.songs[i].client_deleted = now;

								new_songs.push( playlist.songs[i] );
							}
						} else {
							new_songs.push( playlist.songs[i] );
						}
					}

					playlist.songs = new_songs;
				}).then(function() {
					defer.resolve({
						'num': song_num,
						'list_id': list_id,
						'action': 'playlist/remove',
						'result': 'success'
					});
				}).catch(function(err) {
					defer.reject( null, 'Something happened while trying to modify local playlists.', err );
				});

				break;
			}
			case 'clear': {
				db.playlists.where( 'id' ).equals( list_id ).modify(function(playlist) {
					var new_songs = [];
					for( var i = 0; i < playlist.songs.length; i++ ) {
						if( !playlist.songs[i].client_added ) {
							playlist.songs[i].client_added = 0;
							playlist.songs[i].client_deleted = now;

							new_songs.push( playlist.songs[i] );
						}
					}

					playlist.songs = new_songs;
				}).then(function() {
					defer.resolve({
						'list_id': list_id,
						'action': 'playlist/clear',
						'result': 'success'
					});
				}).catch(function(err) {
					defer.reject( null, 'Something happened while trying to modify local playlists.', err );
				});

				break;
			}
			case 'delete': {
				db.playlists.where( 'id' ).equals( list_id ).modify(function(playlist) {
					if( playlist.client_created ) {
						delete this.value;
					} else {
						playlist.client_deleted = now;
					}
				}).then(function() {
					defer.resolve({
						'list_id': list_id,
						'action': 'playlist/clear',
						'result': 'success'
					});
				}).catch(function(err) {
					defer.reject( null, 'Something happened while trying to modify local playlists.', err );
				});

				break;
			}
			case 'create': {
				db.playlists.orderBy('id').limit(1).toArray().then(function(playlists) {
					var new_pls_id = -1;
					if( playlists.length )
						if( playlists[0].id < 0 )
							new_pls_id = playlists[0].id - 1;

					db.playlists.add({
						id: new_pls_id,
						title: params['title'],
						client_created: now,
						songs: [{
							num: song_num,
							client_added: now,
							client_deleted: 0,
						}],
					}).then(function( inserted_id ){
						defer.resolve({
							'num': song_num,
							'list_id': inserted_id,
							'title': params['title'],
							'action': 'playlist/create',
							'result': 'success'
						});
					});
				}).catch(function(err) {
					defer.reject( null, 'Something happened while trying to create your new local playlist: ', err );
				});
				break;
			}
			default: {
				defer.reject( null, 'Invalid parameters for local playlist modification.', null );
			}
		}
	}).catch(function(err){
		defer.reject( null, 'Invalid parameters for local playlist modification.', null );
	});

	return defer;
}

function _doPlaylistAction( post_url, post_data ) {
	if( offline_mode == 'disabled' ) {
		return $.ajax({
			url: post_url,
			method: 'POST',
			dataType: 'json',
			data: post_data,
		});
	} else {
		return $.Deferred(function( defer ) {
			var post_body = {};
			post_data.forEach(function( parameter ) {
				post_body = _parseQueryStringPart( parameter.name + '=' + parameter.value, post_body );
			});
			console.log('[offline/playlists] got this request to change offline playlist: ', post_body);
			var song_num = parseInt( post_body.song_num, 10 ) || 0;
			var list_id = 0, lists;
			if( post_body.list.add ) {
				lists = Object.keys( post_body.list.add );
				list_id = parseInt( lists[0].substr(1), 10 ) || 0;
				console.log( '[offline/playlists] add the song #%d to the playlist #%d', song_num, list_id );
				_performOfflinePlaylistAction( defer, song_num, list_id, 'add' );
			} else
			if( post_body.list.remove ) {
				lists = Object.keys( post_body.list.remove );
				list_id = parseInt( lists[0].substr(1), 10 ) || 0;
				console.log( '[offline/playlists] remove the song #%d from the playlist #%d', song_num, list_id );
				_performOfflinePlaylistAction( defer, song_num, list_id, 'remove' );
			} else
			if( post_body.list.clear ) {
				lists = Object.keys( post_body.list.clear );
				list_id = parseInt( lists[0].substr(1), 10 ) || 0;
				console.log( '[offline/playlists] clear the playlist #%d', list_id );
				_performOfflinePlaylistAction( defer, song_num, list_id, 'clear' );
			} else
			if( post_body.list['delete'] ) {
				lists = Object.keys( post_body.list['delete'] );
				list_id = parseInt( lists[0].substr(1), 10 ) || 0;
				console.log( '[offline/playlists] delete the playlist #%d', list_id );
				_performOfflinePlaylistAction( defer, song_num, list_id, 'delete' );
			} else
			if( post_body.list['create'] ) {
				lists = Object.keys( post_body.list['create'] );
				console.log( '[offline/playlists] create the playlist "%s"', post_body.title );
				_performOfflinePlaylistAction( defer, song_num, list_id, 'create', { 'title': post_body.title } );
			} else

			// TODO: too simple, need to elaborate on this
			defer.reject(null, 'Invalid parameters', {'msg': 'Invalid parameters for the operation.'});
		});
	}
}

// TODO: escape all the values we've got back from the server (handlebars partial?)
function _sendPlaylistForm( ev ) {
	ev.preventDefault();

	var button = $(this);
	if( button.prop( 'disabled' ) )
		return;

	var original_button = button.clone( );
	var original_name = button.attr('name');

	button.children('i').each(function() {
		$(this).removeClass('fa-square fa-check-square fa-plus fa-heart fa-heart-o');
		$(this).addClass('fa-refresh fa-spin');
	});

	var form = button.parents('form').first();
	console.log( '[api/playlists] submitting the form: ', form );

	var post_data = form.serializeArray();

	post_data.push({
		name: original_name,
		value: 1,
	});

	console.log( '[api/playlists] sending post request to %s with this data: ', form.attr('action'), post_data );

	_doPlaylistAction( form.attr('action'), post_data )
	.done( function( data, textStatus, jqXHR ) {
		console.log( '[api/playlists] got this: ', data );

		var new_action = '';
		var change_action = false;
		switch( data.action ) {
			case 'playlist/add':	new_action = 'remove'; change_action = true; break;
			case 'playlist/remove':	new_action = 'add'; change_action = true; break;
			case 'playlist/create':	{
				$('ul.song-playlist-list').each(function() {
					var data_song_num = $(this).attr('data-song-num');
					if( data_song_num == ''+data.num ) {
						$( '<button class="btn btn-link p-a-0 song-playlist-toggle" type="submit" name="list[remove][i'+data.list_id+']" value="1"><i class="fa-li fa fa-check-square" aria-hidden="true"></i>'+data.title+'</button>' ).appendTo(this).wrap( '<li></li>' ).slideDown('slow').on( 'click', _sendPlaylistForm );
					} else {
						$( '<button class="btn btn-link p-a-0 song-playlist-toggle" type="submit" name="list[add][i'+data.list_id+']" value="1"><i class="fa-li fa fa-square" aria-hidden="true"></i>'+data.title+'</button>' ).appendTo(this).wrap( '<li></li>' ).slideDown('slow').on( 'click', _sendPlaylistForm );
					}
				});

				break;
			}
		}

		// revert clicked button back
		button.children('i.fa-refresh').each(function() {
			$(this).removeClass('fa-refresh fa-spin');
			// playlist toggle
			if( button.hasClass('song-playlist-toggle') ) {
				if( new_action == 'remove' ) {
					$(this).addClass('fa-check-square');
				} else {
					$(this).addClass('fa-square');
				}
			}
			// new playlist button
			if( button.hasClass('song-playlist-create') ) {
				$(this).addClass('fa-plus');
			}
		});

		// only for favorites list
		if( data.list_id == 1 ) {
			// heart icon in the header
			$('a[href="#song-'+data.num+'"]').find('.song-heart-label').each(function() {
				$(this).removeClass('fa-heart fa-heart-o song-heart-label-filled song-heart-label-empty');
				if( new_action == 'remove' ) {
					$(this).addClass('fa-heart song-heart-label-filled');
				} else {
					$(this).addClass('fa-heart-o song-heart-label-empty');
				}
			});

			// heart button in the card
			var heart_button = $('#song-'+data.num).find('button.song-heart-button[name="' + original_name + '"]');
			heart_button.children('i').each(function() {
				$(this).removeClass('fa-heart fa-heart-o');
				if( new_action == 'remove' ) {
					$(this).addClass('fa-heart');
				} else {
					$(this).addClass('fa-heart-o');
				}
			});

			if( change_action && !button.hasClass('song-heart-button') )
				heart_button.attr( 'name', 'list['+new_action+'][i'+data.list_id+']' );
		}

		// playlist toggles update (on the song card)
		var playlist_toggle = $('#song-'+data.num+'-popover').find('button.song-playlist-toggle[name="' + original_name + '"]');
		playlist_toggle.children('i').each(function() {
			$(this).removeClass('fa-square fa-check-square');
			if( new_action == 'remove' ) {
				$(this).addClass('fa-check-square');
			} else {
				$(this).addClass('fa-square');
			}
		});

		if( change_action ) {
			// button we just clicked (toggle in popup or heart button)
			button.attr( 'name', 'list['+new_action+'][i'+data.list_id+']' );

			// toggle in the card
			playlist_toggle.attr( 'name', 'list['+new_action+'][i'+data.list_id+']' );
		}
	})
	.fail(function( jqXHR, textStatus, errorThrown ) {
		console.error( 'error while changing playlists: ', errorThrown );
		button.html( original_button.html() );
	});

};

// TODO: allow only one request at the same time
// TODO: think about replacing the stock FontAwesome CSS3 animation with jqueryRotate: http://beneposto.pl/jqueryrotate/
/*
var angle = 0;
setInterval(function(){
	angle = (angle + 9) % 360;
	$("#spinner").rotate({
		angle: angle
	});
},50);
*/
function _animateSongList( container ) {
	var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
	var toastr = (typeof window !== "undefined" ? window['toastr'] : typeof global !== "undefined" ? global['toastr'] : null);
	var username = window.localStorage.getItem( 'username' );
	offline_mode = window.localStorage.getItem( 'offline_mode.' + username ) || offline_mode;

	$(container).find('.song-pref-button').on('click', function( ev ) {
		ev.preventDefault();

		var button = $(this);
		if( button.prop( 'disabled' ) )
			return;

		var original_button = button.clone();

		button.children('i').each(function() {
			$(this).removeClass('fa-plus-circle fa-minus-circle');
			$(this).addClass('fa-refresh fa-spin');
		});

		var form = $(this).parent();

		var default_tempo = parseInt( form.find('span.song-tempo').attr('data-default') ) || 0;
		var default_key = parseInt( form.find('span.song-key').attr('data-default') ) || 0;

		var data = form.serializeArray();

		data.push({
			name: button.attr('name'),
			value: 1,
		});

		console.log( '[api/prefs] sending post request to %s with this data: ', form.attr('action'), data );

		$.ajax({
			url: form.attr('action'),
			method: 'POST',
			dataType: 'json',
			data: data,
		})
		.done( function( data, textStatus, jqXHR ) {
			console.log('[api/prefs] updating the preferences of the song');

			if( data.preferences.tempo ) {
				var custom_tempo = Math.round( ( 1 + 0.04 * data.preferences.tempo ) * default_tempo );
				var custom_tempo_offset = ( ( data.preferences.tempo > 0 )? '+': '-' ) + Math.abs( 4 * data.preferences.tempo ) + '%';

				console.log( '[api/pref] new custom tempo: %s %s', custom_tempo, custom_tempo_offset );

				form.find('span.song-tempo').css('color', 'red').html( custom_tempo + ' (' + custom_tempo_offset + ')' );

				if( data.preferences.tempo >= 7 ) {
					// disable plus
					form.find( 'button[name="tempo_plus"]' ).prop( 'disabled', true );
				} else {
					// enable plus
					form.find( 'button[name="tempo_plus"]' ).prop( 'disabled', false );
				}

				if( data.preferences.tempo <= -7 ) {
					// disable minus
					form.find( 'button[name="tempo_minus"]' ).prop( 'disabled', true );
				} else {
					// enable minus
					form.find( 'button[name="tempo_minus"]' ).prop( 'disabled', false );
				}
			} else {
				form.find('span.song-tempo').css('color', 'black').html(default_tempo);
			}

			if( data.preferences.key ) {
				var custom_key = _keyToString( default_key, data.preferences.key );
				var custom_key_offset = ( ( data.preferences.key > 0 )? '+': '-' ) + Math.abs( data.preferences.key );

				console.log( '[api/pref] new custom key: %s %s', custom_key, custom_key_offset );

				form.find('span.song-key').css('color', 'red').text( custom_key + ' (' + custom_key_offset + ')' );

				if( data.preferences.key >= 6 ) {
					// disable plus
					form.find( 'button[name="key_plus"]' ).prop( 'disabled', true );
				} else {
					// enable plus
					form.find( 'button[name="key_plus"]' ).prop( 'disabled', false );
				}

				if( data.preferences.key <= -6 ) {
					// disable minus
					form.find( 'button[name="key_minus"]' ).prop( 'disabled', true );
				} else {
					// enable minus
					form.find( 'button[name="key_minus"]' ).prop( 'disabled', false );
				}
			} else {
				form.find('span.song-key').css('color', 'black').text( _keyToString( default_key ) );
			}

			button.html( original_button.html() );
		})
		.fail(function( jqXHR, textStatus, errorThrown ) {
			console.error( 'error while changing preferences: ', errorThrown );
			button.html( original_button.html() );
		});
	});

	$(container).find('form.song-search-form').on('submit', function( ev ) {
		ev.preventDefault();

		console.log( 'search form submitted: ', ev );

		var search = $(container).find('form.song-search-form input[name="search"]').first().val();
		taunus.navigate('/search?search=' + search );

		return;
	});

	$(container).find('button.song-plus-button').each(function() {
		$(this).webuiPopover({
			placement: 'bottom-left',
			trigger: 'click',
			animation: 'pop',
			cache: false,
			width: 250
		});
	});

	$(container).find('.song-heart-button').on( 'click', _sendPlaylistForm );
	$(container).find('.song-playlist-toggle').on( 'click', _sendPlaylistForm );
	$(container).find('.song-playlist-create').on( 'click', _sendPlaylistForm );

	$(container).find('select').selectpicker({
		size: 10,
		iconBase: 'fa',
		tickIcon: 'fa-check',
		actionsBox: true,
	});

	$(container).find('.song-lyrics-button').on( 'click', function( ev ) {
		ev.preventDefault();

		var song_num = parseInt( $(ev.target).parent().parent().attr('data-num') ) || 0;
		var lyrics_box = $(container).find('#song-lyrics-'+song_num);
		var data_loaded = parseInt( lyrics_box.attr('data-loaded') ) || 0;

		if( data_loaded ) {
			lyrics_box.collapse('toggle');
		} else {
			lyrics_box.html( '<small><i class="fa fa-spinner fa-pulse fa-fw"></i> Loading...</small>' );
			lyrics_box.collapse('toggle');

			$.ajax({
				method: 'GET',
				url: '/song/lyrics',
				data: {
					num: song_num,
				}
			})
			.done(function(data) {
				lyrics_box.attr('data-loaded', '1')

				if( data.meta.found ) {
					if( data.meta.marked_as_wrong ) {
						lyrics_box.html(
							'<small>Found lyrics for <strong>' + data.meta.title + '</strong> by <strong>' + data.meta.artist + '</strong>, but these were marked as a wrong match'+
							( (data.meta.marked_by_user)? ' by '+data.meta.marked_by_user: ' automatically' ) +
							'.</small>'
						);
					} else if( data.text ) {
						lyrics_box.html(
							'<small>' +
							//'Found lyrics for <strong>' + data.meta.title + '</strong> by <strong>' + data.meta.artist + '</strong> (<a class="song-mark-lyrics-wrong" href="/song/lyrics?num='+song_num+'&mark_wrong_match=1">wrong match?</a>):'+
							'Lyrics for <strong>' + data.meta.title + '</strong> by <strong>' + data.meta.artist + '</strong> <span class="tag tag-default"><i class="fa fa-thumbs-up" aria-hidden="true"></i> (0)</span> <span class="tag tag-default"><i class="fa fa-thumbs-down" aria-hidden="true"></i> (0)</span> <span class="tag tag-default song-mark-lyrics-wrong"><i class="fa fa-times" aria-hidden="true"></i> wrong match (0)</span>' +
							'<br><br>' +
							data.text.replace( /\r\n/g,'<br>' ) +
							'</small>'
						);

						lyrics_box.find('.song-mark-lyrics-wrong').on('click', function(ev) {
							$.ajax({
								method: 'GET',
								url: '/song/lyrics',
								data: {
									num: song_num,
									mark_wrong_match: 1,
								},
							})
							.done(function(data) {
								lyrics_box.find('.song-mark-lyrics-wrong').removeClass('tag-default').addClass('tag-danger');
							});
						});
					} else {
						lyrics_box.html( '<small>No lyrics for that song as of yet. <a href="'+ data.meta.add_url +'">You can add the lyrics here</a>.</small>' );
					}
				} else {
					lyrics_box.html( '<small>Song was not found.</small>' );
				}
			})
			.fail(function() {
				lyrics_box.attr('data-loaded', '1');

				lyrics_box.html( '<small class="text-danger">Error loading lyrics.</small>' );
			});
		}
	});

	$('input[name="released_from"]').datepicker({
		format: "mm.yyyy",
		startDate: "04.2010",
		endDate: "10.2016",
		startView: 1,
		minViewMode: 1,
		maxViewMode: 2,
		orientation: "bottom auto",
		clearBtn: true,
	});

	$('input[name="released_to"]').datepicker({
		format: "mm.yyyy",
		startDate: "04.2010",
		endDate: "10.2016",
		startView: 1,
		minViewMode: 1,
		maxViewMode: 2,
		orientation: "bottom auto",
		clearBtn: true,
	});
}

module.exports = {
	keyToString: _keyToString,
	parseSongForTemplate: _parseSongForTemplate,
	animateSongList: _animateSongList,
	doPlaylistAction: _doPlaylistAction,
	getMenuItems: function( viewer, action ) {
		var menu_items = [
			{ title: 'Home',    action: 'home',        link: '/' },
			{ title: 'Songs',   action: 'song/list',   link: '/songs' },
			{ title: 'Artists', action: 'artist/list', link: '/artists' },
		];

		// TODO: viewer should be present on all pages
		if( viewer ) {
			if( viewer.username ) {
				// TODO: additional check for user's role (anon, guest, user, etc...)
				menu_items.push({ title:viewer.username, action: 'user/profile', link: '/user/'+viewer.username, sub_items: [
					{ title: 'Profile',          action: 'user/profile',  link: '/user/'+viewer.username },
					{ title: 'Settings',         action: 'user/settings', link: '/settings' },
					{ title: 'Offline settings', action: 'user/offline',  link: '/offline' },
					{ title: 'Logout',           action: 'user/logout',   link: '/logout',  divide: true },
				]});
			} else {
				menu_items.push({ title: 'Login', action: 'user/login', link: '/login'});
			}
		} else {
			menu_items.push({ title: 'Login', action: 'user/login', link: '/login'});
		}

		if( action ) {
			for( var menu_index = 0; menu_index < menu_items.length; menu_index++ ) {
				menu_items[menu_index] = _activate_item( menu_items[menu_index], action );
			}
		}

		return menu_items;
	},
	getPageLink: _getPageLink,
	getSkips: function( current_skip, item_count, item_limit, filters, less_pages ) {
		item_count = item_count || 1;
		var pages = {};

		pages.current = {
			number: Math.ceil( current_skip / item_limit ) + 1,
			link: _getPageLink( { skip: current_skip }, filters ),
		};
		pages.total = Math.ceil( item_count / item_limit );

		// is there a page before this one?
		if( pages.current.number > 1 ) {
			pages.previous = {
				number: pages.current.number - 1,
			};

			pages.previous.link = _getPageLink( { skip: (pages.previous.number-1)*item_limit }, filters );
		}

		// is there page after this one?
		if( pages.total > pages.current.number ) {
			pages.next = {
				number: pages.current.number + 1,
			};

			pages.next.link = _getPageLink( { skip: (pages.next.number - 1)*item_limit }, filters );
		}

		// page numbers for pagination
		var page_numbers = [ pages.total ];

		if( !less_pages && pages.total > 1 ) {
			page_numbers.push( pages.total - 1 );

			if( page_numbers.indexOf(2) < 0 )
				page_numbers.push(2);
		}

		if( page_numbers.indexOf(1) < 0 )
			page_numbers.push(1);

		if( pages.current.number > 1 ) {
			if( pages.previous && page_numbers.indexOf( pages.previous.number ) < 0 )
				page_numbers.push( pages.previous.number );
		}
		if( page_numbers.indexOf(pages.current.number) < 0 )
			page_numbers.push(pages.current.number);
		if( pages.current.number < pages.total ) {
			if( pages.next && page_numbers.indexOf(pages.next.number) < 0 )
				page_numbers.push(pages.next.number);
		}

		// sort the pages
		page_numbers.sort( function( a,b ){ return ( a - b ); } );

		var page_links = [];
		var last_page_num = pages.total;
		page_numbers.forEach(function(page_num){
			if( ( page_num > last_page_num ) && ( page_num - last_page_num - 1 ) ) {
				page_links.push({
					is_ellipsis: 1,
				});
			}

			page_links.push({
				number: page_num,
				link: _getPageLink( { skip: (page_num - 1)*item_limit }, filters ),
				is_current: ( page_num == pages.current.number ),
			});
			last_page_num = page_num;
		});
		pages.pagination = page_links;

		return pages;
	},
	getMessagesArray: function( messages_object ) {
		var messages_array = [];
		if( messages_object )
			for( var type in messages_object )
				messages_array.push({ 'type': type, 'alert_type': _getBootstrapAlertType( type ), 'message': messages_object[type] });

		return messages_array;
	},
	getTermsFromString: function ( text ) {
		if ( text ) {
			var allWordsIncludingDups = text.replace( /\.|\,|\-|\\|\'|\"|\/|\:|\;|\&|\!|\(|\)|\�|\�/g, ' ' ).toLowerCase().split(' ');
			var wordSet = allWordsIncludingDups.reduce(function (prev, current) {
				if( current.length > 1 ) {
					prev[current] = true;
				}
				return prev;
			}, {});
			return Object.keys(wordSet);
		}
	},
	formatDuration: function( seconds ) {
		if( seconds <= 0 )
			return '0s';

		var duration = '';
		if( seconds >= 3600 ) {
			duration += Math.floor( seconds / 3600 ) + 'h ';
		}

		duration += Math.floor( ( seconds % 3600 ) / 60 ) + 'm ' + Math.floor( seconds % 60 ) + 's';

		return duration;
	},
	getFullLanguageFromISO: function( language_code ) {
		var lang = _isoLangs[language_code];
		return lang ? lang.name : language_code;
	},
	getGenresFromMask: function( genre_mask ) {
		return _genreMaskToArray( genre_mask, _genre_masks, false ).join(', ');
	},
	getDateFromRelease: _getDateFromRelease,
};