var Handlebars = require("handlebars-wrapper/partials");module.exports = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"row p-y-1 nojs-show\"><div class=\"col-xs-offset-1 col-xs-10\"><div class=\"alert alert-"
    + alias2(alias1((depth0 != null ? depth0.alert_type : depth0), depth0))
    + "\" role=\"alert\">"
    + alias2(alias1((depth0 != null ? depth0.message : depth0), depth0))
    + "</div></div></div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"container\">"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.messages : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"row\"><form method=\"post\"><div class=\"col-xs-offset-1 col-xs-10 col-sm-offset-1 col-sm-2 p-y-05\"><button class=\"btn btn-block btn-success\" name=\"success\" type=\"submit\">Success</button></div><div class=\"col-xs-offset-1 col-xs-10 col-sm-2 p-y-05\"><button class=\"btn btn-block btn-info\" name=\"info\" type=\"submit\">Info</button></div><div class=\"col-xs-offset-1 col-xs-10 col-sm-2 p-y-05\"><button class=\"btn btn-block btn-warning\" name=\"warning\" type=\"submit\">Warning</button></div><div class=\"col-xs-offset-1 col-xs-10 col-sm-2 p-y-05\"><button class=\"btn btn-block btn-danger\" name=\"error\" type=\"submit\">Error</button></div><div class=\"col-xs-offset-1 col-xs-10 col-sm-2 p-y-05\"><a class=\"btn btn-block btn-primary\" href=\"/notifier?message=default\">Redirect</a></div></form></div></div>";
},"useData":true});