'use strict';

var taunus = require('taunus/browser/debug');
var wiring = require('./wiring');
var main = document.getElementById('taunus-body');
var safeson = require('safeson');

var functions = require('../lib/functions.client.js');

toastr.options = {
	positionClass: 'toast-bottom-right',
	toastClass: 'alert',
	iconClasses: {
		error: 'alert-danger',
		info: 'alert-info',
		success: 'alert-success',
		warning: 'alert-warning'
	},
	showDuration: 300,
	hideDuration: 500
};

Offline.options = {
	interceptRequests: false,
	requests: false,
	checks: {
		xhr: {
			url: '/ping'
		}
	},
	reconnect: {
		initialDelay: 10,
		delay: 10
	}
};

var offline_toastr;
var offline_toastr_message;
Offline.on('down', function() {
	offline_toastr = toastr.error( 'You\'re currently offline.', null, { timeOut: 0, extendedTimeOut: 0 } );
	offline_toastr_message = offline_toastr.find('.toast-message');
});
Offline.on('up', function() {
	if( offline_toastr )
		offline_toastr.remove();

	toastr.success( 'You\'re back online!', null, { showDuration: 0 } );
});
Offline.on('reconnect:tick', function() {
	if( offline_toastr ) {
		offline_toastr.removeClass('alert-danger alert-success').addClass('alert-warning');
		offline_toastr_message.html( 'Will try to reconnect in ' + Offline.reconnect.remaining + 's.' );
	}
});
Offline.on('reconnect:connecting', function() {
	if( offline_toastr ) {
		offline_toastr.removeClass('alert-danger alert-success').addClass('alert-warning');
		offline_toastr_message.html( 'Trying to reconnect...' );
	}
});
Offline.on('reconnect:failure', function() {
	Offline.options.reconnect.delay = Math.min( Math.ceil( ( Offline.options.reconnect.delay || 5 ) * 1.2 ), 300 );
});

taunus.once('start', function( container, model, route ) {
	var new_messages = functions.getMessagesArray( model.messages );

	if( toastr )
		for( var i = 0; i < new_messages.length; i++ )
			toastr[new_messages[i].type]( new_messages[i].message );
});

window.taunusReady = function(model) {
	window.taunusReady = model;
};

/*
// it's probably going to work like that:
functions.getViewModel( route )
.done(function( model ) {
	taunusReady( model );
})
.fail(function(error_text) {
	// just show the toastr
});

// where getViewModel is a function that looks if offline mode is enabled and performs appropriate actions:
function getViewModel( route ) {
	return $.Deferred(function( defer ) {
		var username = window.localStorage.getItem( 'username' );

		if( !username ) {
			defer.resolve(inlineboot());
			return;
		}

		if( window.localStorage.getItem( 'offline_mode.'+username ) !== 'enabled' ) {
			defer.resolve(inlineboot());
			return;
		}

		// here we can load our model
	});
};
*/

taunus.mount( main, wiring, {
	bootstrap: 'manual',
	links: true,
	forms: false,
	xhrOptions: {
		timeout: 5000,
		headers: {
			"X-Requested-With": "XMLHttpRequest",
		},
	},
});

function inlineboot() {
	var script = document.getElementById( 'taunus-model' );
	var data = safeson.decode( script.innerText || script.textContent );
	return data;
}

taunusReady( inlineboot() );

$('a.dropdown-toggle').attr('href', '#');

var overlay_show = function (route, context) {
	$('#overlay').show();
};
var overlay_hide = function (route, context) {
	$('#overlay').hide();
};

var db;
if( window.localStorage.getItem( 'username' ) ) {
	console.log( 'loaded username "%s" from localStorage', window.localStorage.getItem( 'username' ) );

	require('songs_db')( window.localStorage.getItem( 'username' ) ).then(function(_db) {
		db = _db;
	});
} else {
	console.log( 'no username in localStorage' );
}

var _dateToRelease = function( date ) {
	var matches = /([0-9]{1,2})\.([0-9]{4})/.exec(date);
	if( !matches )
		return null;

	return parseInt( matches[2] + matches[1], 10 );
}

var _valid_genre_masks = [2,4,8,16,32,64,128,256,512,1024];
var _valid_genre_mask = function(array){ var sum=0; for(var i=array.length; i--;) { sum+=array[i]; } return sum; }(_valid_genre_masks);
var _checkGenreMask = function( genre_mask ) {
	return ( genre_mask & _valid_genre_mask ) == genre_mask;
};

var _preparePlaylistsForViewer = function( playlists ) {
	var existing_playlists = [];
	for( var pls_index = 0; pls_index < playlists.length; pls_index++ ) {
		if( !playlists[pls_index].client_deleted ) {
			var exisiting_song_nums = [];

			for( var song_index = 0; song_index < playlists[pls_index].songs.length; song_index++ ) {
				if( !playlists[pls_index].songs[song_index].client_deleted ) {
					exisiting_song_nums.push( playlists[pls_index].songs[song_index].num );
				}
			}

			existing_playlists.push({
				id: playlists[pls_index].id,
				title: playlists[pls_index].title,
				song_nums: exisiting_song_nums,
			});
		}
	}

	return existing_playlists;
}

var basic_offline_actions = [ 'home', 'song/list', 'artist/songs', 'user/offline', 'user/profile', 'user/settings', 'user/playlist/songs' ];
var full_offline_actions = basic_offline_actions.concat( [ 'song/search', 'artist/list' ] );

// artst/songs - whole songs objects to cache
// song/search - with index, after sorting
var cached_request;
var cached_songs;

taunus.intercept('*', function(ev) {
	overlay_show();

	var username = window.localStorage.getItem( 'username' );
	var offline_mode = window.localStorage.getItem( 'offline_mode.'+username ) || 'disabled';
	var fetch = true;

	if(
			// user explicitly wants to fetch all data from offline cache
			( offline_mode == 'forced' )
			// user wants some data to come from offline cache:
		||	( offline_mode == 'full' && ( full_offline_actions.indexOf(ev.route.action) >= 0) )
		||	( offline_mode == 'basic' && ( basic_offline_actions.indexOf(ev.route.action) >= 0) )
	)
	{
		fetch = false;
	}

	if( fetch ) {
		ev.canPreventDefault = false;
		ev.preventDefault();
		return;
	}

	ev.preventDefault(); // prevent further actions
	console.log( '[offline] trying to fetch the data from offline cache (offline_mode = "%s")', offline_mode );

	if( cached_songs && ( cached_request != ev.route.action + '.' + ev.route.query.search + '.' + ev.route.params.artist ) ) {
		cached_songs = null;
		cached_request = null;
	}

	var skip = parseInt( ev.route.query.skip, 10 ) || 0;

	var settings = JSON.parse( window.localStorage.getItem( 'settings.'+username ) ) || {
		username: username,
		display_name: username,
		email: '',
		artists_per_page: 20,
		compare_release: 0,
		show_device: 0,
		songs_per_page: 20,
	};

	var settings_allowed = JSON.parse( window.localStorage.getItem( 'settings_allowed.'+username ) ) || {
		artists_per_page_max: 100,
		songs_per_page_max: 100,
		available_releases: [{ id: 0, actual: true }],
	};

	switch( ev.route.action ) {
		case 'home': {
			taunus.navigate( ev.route.url, { strict: true, dry: true, force: true } );

			taunus.partial(
				document.getElementById('taunus-body'),
				'home',
				{
					offline: true,
					title: 'Home | [not] AST catalog',
					viewer: {
						username: username,
						group: 'user',
						settings: settings,
					},
				}
			);

			overlay_hide();
			break;
		}
		case 'user/offline': {
			taunus.navigate( ev.route.url, { strict: true, dry: true, force: true } );

			taunus.partial(
				document.getElementById('taunus-body'),
				'user/offline',
				{
					offline: true,
					title: 'Offline settings | [not] AST catalog',
					viewer: {
						username: username,
						display_name: settings.display_name,
						group: 'user',
						settings: settings,
					},
				}
			);

			overlay_hide();
			break;
		}
		case 'user/settings': {
			taunus.navigate( ev.route.url, { strict: true, dry: true, force: true } );

			taunus.partial(
				document.getElementById('taunus-body'),
				'user/settings',
				{
					offline: true,
					viewer: {
						username: username,
						display_name: settings.display_name,
						group: 'user',
					},
					settings: settings,
					settings_allowed: settings_allowed,
				}
			);

			overlay_hide();
			break;
		}
		case 'song/list': {
			db.songs.count().then(function(songs_count) {
				db.songs.orderBy('artist').offset( skip ).limit( settings.songs_per_page ).toArray().then(function(songs) {
					db.playlists.toArray().then(function(playlists) {
						taunus.navigate( ev.route.url, { strict: true, dry: true, force: true } );

						taunus.partial(
							document.getElementById('taunus-body'),
							'song/list',
							{
								offline: true,
								title: 'Songs | [not] AST catalog',
								skip: skip,
								songs: songs,
								viewer: {
									username: username,
									display_name: settings.display_name,
									group: 'user',
									playlists: _preparePlaylistsForViewer( playlists ),
									settings: settings,
								},
								totals: {
									duration: 0,
									songs: songs_count
								},
								filters: {},
								available_filters: {},
							}
						);

						overlay_hide();
					});
				});
			}).catch(function(err) {
				console.error('Error while fetching songs: ', err);
				overlay_hide();
			});
			break;
		}
		case 'song/search': {
			var search = ev.route.query.search;

			var num_search = parseInt( search, 10 ) || 0;
			num_search = ((num_search >= 100) && (num_search < 100000))? num_search : 0;
			var search_terms = functions.getTermsFromString( ''+search );
			console.log( '[offline/search] search query: %s (number parsed: %d)', search_terms, num_search );

			if( cached_songs ) {
				console.log( '[offline/search] got the results for this request from the cache' );

				db.playlists.toArray().then(function(playlists) {
					console.log( '[offline/search] selected %d playlists from db', playlists.length );

					var available_filters = JSON.parse( window.localStorage.getItem( 'offline_filters.' + username ) );
					available_filters.genres = _valid_genre_masks;

					taunus.navigate( ev.route.url, {strict: true, dry: true, force:true} );

					taunus.partial(
						document.getElementById('taunus-body'),
						'song/list',
						{
							offline: true,
							result_type: 'result_search',
							skip: skip,
							songs: cached_songs.slice( skip, skip + settings.songs_per_page ),
							search: search,
							subtitle: search? '"' + search + '"': 'All songs',
							title: ( search? '"' + search + '" - search results': 'All songs' ) + ' | [not] AST catalog',
							viewer: {
								username: username,
								display_name: settings.display_name,
								group: 'user',
								playlists: _preparePlaylistsForViewer( playlists ),
								settings: settings
							},
							totals: {
								duration: 0,
								songs: cached_songs.length,
							},
							filters: {},
							available_filters: available_filters,
						}
					);

					overlay_hide();
				});
			} else {
				db.songs_index.where(":id").anyOf(search_terms).toArray().then(function(words) {
					var first_filter = true;

					// TODO: should check the search for strings with whitespaces and stuff
					if( search ) {
						console.log( '[offline/search] search query returned these words: ', words );

						var index = {};
						if( num_search ) {
							index[num_search] = 1000; // very high weight for a suitable number in search query
						}

						for( var word_index = 0; word_index < words.length; word_index++ )
							for( var num_index = 0; num_index < words[word_index].songs.length; num_index++ )
								index[words[word_index].songs[num_index]] = ( index[words[word_index].songs[num_index]] || 0 ) + 1;

						console.log( '[offline/search] search query returned these songs (non-unique): ', index );
						console.log( '[offline/search] search query returned songs: ', Object.keys(index) );

						var nums = Object.keys(index).map( function( num ) { return parseInt( num, 10 ); } );

						var songs_count = nums.length;

						var db_songs = db.songs.where(':id').anyOf( nums );

						first_filter = false;
					} else {
						console.log( '[offline/search] empty search query, applying filters only' );

						// TODO: should be an actual count
						var songs_count = 0;

						var db_songs = db.songs;
					}

					if( ev.route.query['release'] ) {
						var released_from = _dateToRelease( ev.route.query['release'] );
						var released_to = _dateToRelease( ev.route.query['release'] );
					}

					if( ev.route.query['released_from'] ) {
						var released_from = _dateToRelease( ev.route.query['released_from'] );
						// TODO: have to check if date is valid and is inside of allowed range
					}

					if( ev.route.query['released_to'] ) {
						var released_to = _dateToRelease( ev.route.query['released_to'] );
						// TODO: have to check if date is valid and is inside of allowed range
					}

					if( ev.route.query['duration_from'] )
						var duration_from = parseInt( ev.route.query['duration_from'], 10 ) || 0;

					if( ev.route.query['duration_to'] )
						var duration_to = parseInt( ev.route.query['duration_to'], 10 ) || 0;

					// releases
					if( ev.route.query['releases'] ) {
						var releases_key = 'releases';
					} else if( ev.route.query['releases[]'] ) {
						var releases_key = 'releases[]';
					}

					if( releases_key )
						if( ev.route.query[releases_key].constructor === Array )
							var releases = ev.route.query[releases_key].map(function(release){ return parseInt( release, 10 ); });

					// languages
					if( ev.route.query['languages'] ) {
						var language_key = 'languages';
					} else if( ev.route.query['languages[]'] ) {
						var language_key = 'languages[]';
					}

					if( language_key ) 
						if( ev.route.query[language_key].constructor === Array )
							var languages = ev.route.query[language_key].map( function(language){ return language.trim(); } );
						else
							var languages = [ ev.route.query[language_key].trim() ];

					// genres
					if( ev.route.query['genres'] ) {
						var genres_key = 'genres';
					} else if( ev.route.query['genres[]'] ) {
						var genres_key = 'genres[]';
					}

					if( genres_key )
						if( ev.route.query[genres_key].constructor === Array ) {
							var genres = [];
							for( var i = ev.route.query[genres_key].length; i--; ) {
								var mask = parseInt( ev.route.query[genres_key][i], 10 );
								if( _checkGenreMask( mask ) )
									genres.push( mask );
							}
						} else {
							var genres = [ parseInt( ev.route.query[genres_key], 10 ) ];
						}

					if( releases ) {
						if( first_filter ) {
							db_songs = db_songs.where( 'release' ).anyOf( releases );
							first_filter = false;
						} else {
							db_songs = db_songs.filter( function(song) { return ( releases.indexOf(song.release) >= 0 ) } );
						}
					} else if( released_from && released_to ) {
						if( first_filter ) {
							db_songs = db_songs.where( 'release' ).between( released_from, released_to, true, true );
							first_filter = false;
						} else {
							db_songs = db_songs.filter( function(song) { return ( song.release >= released_from ) && ( song.release <= released_to ); } );
						}
					} else {
						if( released_from )
							if( first_filter ) {
								db_songs = db_songs.where( 'release' ).aboveOrEqual( released_from );
								first_filter = false;
							} else {
								db_songs = db_songs.filter( function(song) { return ( song.release >= released_from ) } );
							}

						if( released_to )
							if( first_filter ) {
								db_songs = db_songs.where( 'release' ).belowOrEqual( released_to );
								first_filter = false;
							} else {
								db_songs = db_songs.filter( function(song) { return ( song.release <= released_to ) } );
							}
					}

					if( duration_from && duration_to ) {
						if( first_filter ) {
							db_songs = db_songs.where( 'duration' ).between( duration_from, duration_to, true, true );
							first_filter = false;
						} else {
							db_songs = db_songs.filter( function(song) { return ( song.duration >= duration_from ) && ( song.duration <= duration_to ); } );
						}
					} else {
						if( duration_from )
							if( first_filter ) {
								db_songs = db_songs.where( 'duration' ).aboveOrEqual( duration_from );
								first_filter = false;
							} else {
								db_songs = db_songs.filter( function(song) { return ( song.duration >= duration_from ) } );
							}

						if( duration_to )
							if( first_filter ) {
								db_songs = db_songs.where( 'duration' ).belowOrEqual( released_to );
								first_filter = false;
							} else {
								db_songs = db_songs.filter( function(song) { return ( song.duration <= duration_to ) } );
							}
					}

					if( languages )
						if( first_filter ) {
							db_songs = db_songs.where( 'languages' ).anyOf( languages );
							first_filter = false;
						} else {
							db_songs = db_songs.filter( function(song) { return ( languages.indexOf(song.languages) >= 0 ) } );
						}

					if( genres ) {
						var mask = 0;
						for (var i = genres.length; i--;) {
							mask += genres[i];
						}
						if( mask ) {
							db_songs = db_songs.filter(function( song ) {
								return ( song.genre & mask );
							});
						}
					}

					if( !index ) {
						db_songs = db_songs.offset( skip ).limit( settings.songs_per_page );
					}

					db_songs.sortBy('artist').then(function(songs) {
						console.log( '[offline/search] selected %d songs from db', songs.length );
						if( index ) {
							songs = songs.sort(function( a, b ) {
								return (index[a.num] > index[b.num]) ? -1 : (index[a.num] < index[b.num]) ? 1 : 0;
							});

							cached_request = ev.route.action + '.' + ev.route.query.search + '.' + ev.route.params.artist;
							cached_songs = songs;

							songs = songs.slice( skip, skip + settings.songs_per_page );
						}

						db.playlists.toArray().then(function(playlists) {
							console.log( '[offline/search] selected %d playlists from db', playlists.length );

							var available_filters = JSON.parse( window.localStorage.getItem( 'offline_filters.' + username ) );
							available_filters.genres = _valid_genre_masks;

							taunus.navigate( ev.route.url, {strict: true, dry: true, force:true} );

							taunus.partial(
								document.getElementById('taunus-body'),
								'song/list',
								{
									offline: true,
									result_type: 'result_search',
									skip: skip,
									songs: songs,
									search: search,
									subtitle: search? '"' + search + '"': 'All songs',
									title: ( search? '"' + search + '" - search results': 'All songs' ) + ' | [not] AST catalog',
									viewer: {
										username: username,
										display_name: settings.display_name,
										group: 'user',
										playlists: _preparePlaylistsForViewer( playlists ),
										settings: settings
									},
									totals: {
										duration: 0,
										songs: songs_count,
									},
									filters: {
										releases: releases,
										released_from: released_from,
										released_to: released_to,
										duration_from: duration_from,
										duration_to: duration_to,
										languages: languages,
										genres: genres,
									},
									available_filters: available_filters,
								}
							);

							overlay_hide();
						});
					});
				}).catch(function(err) {
					console.error('Error while fetching songs from db: ', err);
					overlay_hide();
				});
			}

			break;
		}
		case 'artist/songs': {
			var artist = ev.route.params.artist;

			if( cached_songs ) {
				console.log( '[offline/artist] got %d while looking for artist "%s" (search cache): ', cached_songs.length, artist, cached_songs );

				db.playlists.toArray().then(function(playlists) {
					taunus.navigate( ev.route.url, {strict: true, dry: true, force: true} );

					taunus.partial(
						document.getElementById('taunus-body'),
						'song/list',
						{
							offline: true,
							artist: artist,
							result_type: 'result_artist',
							skip: skip,
							songs: cached_songs.slice( skip, skip + settings.songs_per_page ),
							subtitle: artist,
							title: 'Songs by "' + artist + '" | [not] AST catalog',
							viewer: {
								username: username,
								display_name: settings.display_name,
								group: 'user',
								playlists: _preparePlaylistsForViewer( playlists ),
								settings: settings
							},
							totals: {
								duration: 0,
								songs: cached_songs.length,
							},
							filters: {},
							available_filters: {},
						}
					);

					overlay_hide();
				});
			} else {
				db.songs.where('artists').equalsIgnoreCase(artist).sortBy('title').then(function(songs) {
					console.log( '[offline/artist] got %d while looking for artist "%s" (database): ', songs.length, artist, songs );

					cached_request = ev.route.action + '.' + ev.route.query.search + '.' + ev.route.params.artist;
					cached_songs = songs;

					db.playlists.toArray().then(function(playlists) {
						taunus.navigate( ev.route.url, {strict: true, dry: true, force: true} );

						taunus.partial(
							document.getElementById('taunus-body'),
							'song/list',
							{
								offline: true,
								artist: artist,
								result_type: 'result_artist',
								skip: skip,
								songs: songs.slice( skip, skip + settings.songs_per_page ),
								subtitle: artist,
								title: 'Songs by "' + artist + '" | [not] AST catalog',
								viewer: {
									username: username,
									display_name: settings.display_name,
									group: 'user',
									playlists: _preparePlaylistsForViewer( playlists ),
									settings: settings
								},
								totals: {
									duration: 0,
									songs: songs.length,
								},
								filters: {},
								available_filters: {},
							}
						);

						overlay_hide();
					});
				}).catch(function(err) {
					console.error('Error while fetching songs: ', err);
					overlay_hide();
				});
			}

			break;
		}
		case 'user/playlist/songs': {
			if( username.toLowerCase() != ev.route.params.username.toLowerCase() ) {
				overlay_hide();
				toastr.error( "You can only access your own playlists while offline." );
				return;
			}

			db.playlists.toArray().then(function( playlists ) {
				if( !playlists.length ) {
					overlay_hide();
					toastr.error( "You don't have any playlists downloaded for offline use." );
					return;
				}

				var playlist_owner = ev.route.params.username;
				var playlist_id = parseInt( ev.route.params.list, 10 ) | 0;

				if( playlist_owner != username ) {
					overlay_hide();
					toastr.error( "You can only access your playlist when you're offline." );
					return;
				}

				var existing_playlists = _preparePlaylistsForViewer( playlists );

				var pls_id = -1, i = existing_playlists.length;
				while( ( pls_id < 0 ) && i-- ) {
					if( playlist_id == existing_playlists[i].id ) {
						pls_id = i;
					}
				}

				if( pls_id < 0 ) {
					overlay_hide();
					toastr.error( "You don't have this playlist available offline." );
					return;
				}

				console.log( '[offline/playlist] got these songs for the playlist #%d: ', existing_playlists[pls_id].id, existing_playlists[pls_id].song_nums );

				db.songs.where(':id').anyOf( existing_playlists[pls_id].song_nums ).toArray().then(function(songs) {
					// time to calculate total duration of the playlist
					var j = songs.length, total_duration = 0;
					while( j-- )
						if( songs[j].available )
							total_duration += songs[j].duration;

					// naive, non-destroying and perfectly readable approach
					var sorted_songs = [];
					var sorting_order = existing_playlists[pls_id].song_nums;
					var search, sort_found;
					var i = 0, j = 0;
					while( j < sorting_order.length ) {
						search = sorting_order[j];
						sort_found = false;
						i = 0;
						while( !sort_found && ( i < songs.length ) ) {
							if( songs[i]['num'] == search ) {
								sort_found = true;
								sorted_songs.push( songs[i] );
							}
							i++;
						}
						j++;
					}

					taunus.navigate( ev.route.url, {strict: true, dry: true, force:true} );

					taunus.partial(
						document.getElementById('taunus-body'),
						'user/playlist/songs',
						{
							offline: true,
							result_type: 'result_playlist',
							skip: skip,
							songs: sorted_songs.slice( skip, skip + settings.songs_per_page ),
							list_id: existing_playlists[pls_id].id,
							subtitle: existing_playlists[pls_id].title,
							title: '"' + existing_playlists[pls_id].title + '" playlist by ' + username +' | [not] AST catalog',
							viewer: {
								username: username,
								display_name: settings.display_name,
								group: 'user',
								is_owner: true,
								playlists: existing_playlists,
								settings: settings
							},
							owner: {
								username: username,
							},
							totals: {
								duration: total_duration,
								songs: existing_playlists[pls_id].song_nums.length
							},
							filters: {},
							available_filters: {},
						}
					);

					overlay_hide();
				});
			}).catch(function(err) {
				console.error('Error while fetching songs: ', err);
				overlay_hide();
			});

			break;
		}
		case 'artist/list': {
			db.artists.count().then(function(artists_count) {
				db.artists.orderBy('name').offset( skip ).limit( settings.artists_per_page ).toArray().then(function(artists) {
					taunus.navigate( ev.route.url, { strict: true, dry: true, force: true } );

					taunus.partial(
						document.getElementById('taunus-body'),
						'artist/list',
						{
							offline: true,
							title: 'Artists | [not] AST catalog',
							skip: skip,
							artists: artists.map(function(artist) {
								return {
									name: artist.name,
									songs_count: ( artist.songs_available + artist.songs_unavailable ),
									available: ( artist.songs_available > 0 ),
								};
							}),
							viewer: {
								username: username,
								display_name: settings.display_name,
								group: 'user',
								settings: settings,
							},
							totals: {
								artists: artists_count
							},
						}
					);

					overlay_hide();
				});
			}).catch(function(err) {
				console.error('Error while fetching artists: ', err);
				overlay_hide();
			});
			break;
		}
		case 'user/profile': {
			if( username.toLowerCase() != ev.route.params.username.toLowerCase() ) {
				overlay_hide();
				toastr.error( "You can only access your own profile while offline." );
				return;
			}

			db.playlists.toArray().then(function( playlists ) {
				var group = 'user';
				var existing_playlists = [];
				for( var pls_index = 0; pls_index < playlists.length; pls_index++ ) {
					if( !playlists[pls_index].client_deleted ) {
						var songs_count = 0;

						for( var song_index = playlists[pls_index].songs.length; song_index-- ; ) {
							if( !playlists[pls_index].songs[song_index].client_deleted )
								songs_count++;
						}

						playlists[pls_index].songs_count = songs_count;

						existing_playlists.push( playlists[pls_index] );
					}
				}

				taunus.navigate( ev.route.url, { strict: true, dry: true, force: true } );

				taunus.partial(
					document.getElementById('taunus-body'),
					'user/profile',
					{
						offline: true,
						title: username + "'s profile | [not] AST catalog",
						viewer: {
							username: username,
							display_name: settings.display_name,
							role: group,
						},
						owner: {
							username: username,
							display_name: settings.display_name,
							group: group.charAt(0).toUpperCase() + group.slice(1),
							own_profile: 1,
							playlists: existing_playlists,
						},
					}
				);

				overlay_hide();
			}).catch(function(err) {
				console.error('Error while fetching profile: ', err);
				overlay_hide();
			});
			break;
		}
		default: {
			overlay_hide(); // TODO: find out why this is needed; is there even a need for this now?
			toastr.error( 'This action is not available offline.' );
		}
	}
});

taunus.on( 'render', function( container, model, route ) {
	console.log('render triggered: ', model);
} );
taunus.on( 'fetch.start', function() {
	console.log('fetch.start triggered!');
	overlay_show();
} );
taunus.on( 'fetch.done',  function( route, context, data ) {
	console.log( 'fetch.done triggered:', data );

	if( typeof data != 'object' ) {
		overlay_hide();
		toastr.error( 'Invalid data received from server.' );
		throw new Error('Invalid data received from server.');
	}

	if( 'model' in data ) {
		var new_messages = functions.getMessagesArray( data.model.messages );

		if( toastr )
			for( var i = 0; i < new_messages.length; i++ )
				toastr[new_messages[i].type]( new_messages[i].message );
	}

	overlay_hide();
} );
taunus.on( 'fetch.abort', function() {
	console.log('fetch.abort triggered!');
	overlay_hide();
} );
taunus.on( 'fetch.error', function( route, context, err ) {
	console.log( 'fetch.error triggered:', err );
	overlay_hide();
} );
