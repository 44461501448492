'use strict'

// server/views/error.js

var tmpl = {
	error:	require('../templates/show_error'),
	navbar:	require('../templates/navbar'),
	footer:	require('../templates/footer'),
};

var functions = require('../../lib/functions.client.js');

module.exports = function( model ) {
	model.menu_items = functions.getMenuItems( model.viewer, model.route.action );
	model.messages = functions.getMessagesArray( model.messages );

	switch( model.error_code ) {
		case 404: {
			model.error_description = 'Looks like we can\'t find the page you\'ve tried to access. Check your link or just try going <a href="/">home</a>.';
			break;
		}
		case 403: {
			model.error_description = 'You\'re not allowed to access this page. Sorry about that.';
			break;
		}
		case 500:
		case 502:
		case 503:
		default: {
			model.error_description = 'Something bad and completely unexpected happened. Try refreshing the page in a few minutes.';
		}
	}

	return tmpl.navbar( model ) + '<main>' + tmpl.error( model ) + '</main>' + tmpl.footer( model );
}