'use strict';

// server/views/song/list.js

var functions = require('../../../lib/functions.client.js');

var tmpl = {
	list: require('../../templates/song/list'),
	navbar: require('../../templates/navbar'),
	footer: require('../../templates/footer'),
};

module.exports = function list( model ) {
	if( model.songs ) {
		model.songs = model.songs.map( functions.parseSongForTemplate );
	} else {
		model.songs = [];
	}

	if( model.offline ) {
		console.log( 'offline songs prepared for template: ', model.songs );
	}

	if( 'totals' in model )
		model.totals.songs = (model.totals.songs || 0);
	else
		model.totals = {songs: 0};

	var songs_per_page = 20;

	if( model.viewer )
		if( 'settings' in model.viewer )
			songs_per_page = (model.viewer.settings.songs_per_page || 20);

	if( 'totals' in model )
		if( 'duration' in model.totals )
			if( model.totals.duration )
				model.totals.duration = '' + Math.floor( model.totals.duration / 60 ) + 'm ' + Math.floor( model.totals.duration % 60 ) + 's';

	if( model.viewer ) {
		if( 'playlists' in model.viewer ) {
			var in_playlist = false;

			model.songs.forEach( function( song, index ) {
				model.songs[index].playlists = [];

				model.viewer.playlists.forEach( function( playlist ) {
					in_playlist = ( playlist.song_nums.indexOf( model.songs[index].num ) >= 0 );

					if( in_playlist && playlist.id == 1 ) {
						model.songs[index].favorite = true;
					}

					model.songs[index].playlists.push({
						id: playlist.id,
						title: playlist.title,
						"in_playlist": in_playlist
					});
				});
			});
		}

		if( 'group' in model.viewer ) {
			if( model.viewer.group == 'user' || model.viewer.group == 'admin' ) {
				model.viewer.can_change_preferences = true;
				model.viewer.can_have_playlists = true;
				model.viewer.can_create_playlists = true;
				model.viewer.can_view_lyrics = true;
			}
		}
	}

	var filtered = false;

	if( model.filters.released_from ) {
		model.filters.released_from = functions.getDateFromRelease( model.filters.released_from );
		filtered = true;
	}

	if( model.filters.released_to ) {
		model.filters.released_to = functions.getDateFromRelease( model.filters.released_to );
		filtered = true;
	}

	var filters_and_search = model.filters;
	filters_and_search['search'] = model.search;
	model.pages = functions.getSkips( model.skip, model.totals.songs, songs_per_page, filters_and_search );

	if( model.available_filters.released_min )
		model.available_filters.released_min = functions.getDateFromRelease( model.available_filters.released_min );

	if( model.available_filters.released_max )
		model.available_filters.released_max = functions.getDateFromRelease( model.available_filters.released_max );

	if( model.filters.duration_from || model.filters.duration_to) {
		filtered = true;
	}

	if( model.filters.languages ) {
		filtered = true;
	} else {
		model.filters.languages = [];
	}

	if( model.available_filters.languages ) {
		model.available_filters.languages = model.available_filters.languages.map(function(lang_code) {
			return {
				code: lang_code,
				name: functions.getFullLanguageFromISO( lang_code ),
				active: (model.filters.languages.indexOf(lang_code) >= 0)? 'selected': '',
			};
		});
	}

	if( model.filters.genres ) {
		filtered = true;
	} else {
		model.filters.genres = [];
	}

	if( model.available_filters.genres ) {
		model.available_filters.genres = model.available_filters.genres.map(function(genre_mask) {
			return {
				mask: genre_mask,
				name: functions.getGenresFromMask( genre_mask ),
				active: (model.filters.genres.indexOf(genre_mask) >= 0)? 'selected': '',
			};
		});
	}

	model.filtered = filtered;

	model.menu_items = functions.getMenuItems( model.viewer, 'song/list' );
	model.messages = functions.getMessagesArray( model.messages );

	return tmpl.navbar( model ) + '<main>' + tmpl.list( model ) + '</main>' + tmpl.footer( model );
}
