'use strict';

// server/views/artist/list.js

var functions = require('../../../lib/functions.client.js');

var tmpl = {
	list: require('../../templates/artist/list'),
	navbar: require('../../templates/navbar'),
	footer: require('../../templates/footer'),
};

module.exports = function ( model ) {
	model.artists = model.artists.map(function(artist) {
		artist.link = '<a href="' + model.taunus.resolve( 'artist/songs', { artist: encodeURIComponent(artist.name) } ) + '">' + artist.name + '</a>';

		return artist;
	});

	model.menu_items = functions.getMenuItems( model.viewer, model.route.action );
	model.pages = functions.getSkips( model.skip, model.totals.artists || 0, model.viewer.settings.artists_per_page || 20 );

	return tmpl.navbar( model ) + '<main>' + tmpl.list( model ) + '</main>' + tmpl.footer( model );
}
