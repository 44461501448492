'use strict';

// server/views/user/playlist/songs.js

var functions = require('../../../../lib/functions.client.js');

var tmpl = {
	list: require('../../../templates/user/playlist/songs'),
	navbar: require('../../../templates/navbar'),
	footer: require('../../../templates/footer'),
};

module.exports = function list( model ) {
	if( model.songs ) {
		model.songs = model.songs.map( functions.parseSongForTemplate );
	} else {
		model.songs = [];
	}

	if( model.offline ) {
		console.log( 'offline songs prepared for template: ', model.songs );
	}

	if( 'totals' in model ) {
		model.totals.songs = (model.totals.songs || 0);

		if( 'duration' in model.totals ) {
			model.totals.duration = model.totals.duration || 0;
			model.totals.duration = functions.formatDuration( model.totals.duration );
		}
	} else
		model.totals = {songs: 0};

	var songs_per_page = 20;

	if( model.viewer )
		if( 'settings' in model.viewer )
			songs_per_page = (model.viewer.settings.songs_per_page || 20);

	if( model.viewer ) {
		if( 'playlists' in model.viewer ) {
			var in_playlist = false;

			model.songs.forEach( function( song, index ) {
				if( !model.songs[index] ) return;

				model.songs[index].playlists = [];

				model.viewer.playlists.forEach( function( playlist ) {
					in_playlist = ( playlist.song_nums.indexOf( model.songs[index].num ) >= 0 );

					if( in_playlist && playlist.id == 1 ) {
						model.songs[index].favorite = true;
					}

					model.songs[index].playlists.push({
						id: playlist.id,
						title: playlist.title,
						"in_playlist": ( playlist.song_nums.indexOf( model.songs[index].num ) >= 0 )
					});
				});
			});
		}

		if( 'group' in model.viewer ) {
			if( model.viewer.group == 'user' || model.viewer.group == 'admin' ) {
				model.viewer.can_like = true;
				model.viewer.can_change_preferences = true;
				model.viewer.can_have_playlists = true;
				model.viewer.can_view_lyrics = true;
			}
		}
	}

	var filters = {
		'released_from': model.filters.released_from,
		'released_to': model.filters.released_to,
		'duration_from': model.filters.duration_from,
		'duration_to': model.filters.duration_to,
		'languages': model.filters.languages,
		'genres': model.filters.genres,
	}

	model.menu_items = functions.getMenuItems( model.viewer, model.route.action );
	model.pages = functions.getSkips( model.skip, model.totals.songs, songs_per_page, filters );
	model.messages = functions.getMessagesArray( model.messages );

	return tmpl.navbar( model ) + '<main>' + tmpl.list( model ) + '</main>' + tmpl.footer( model );
}
