var handlebars = require('./runtime'); handlebars.registerPartial("pagination", handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<li class=\"page-item\"><a class=\"page-link\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.previous : depth0)) != null ? stack1.link : stack1), depth0))
    + "\" aria-label=\"Previous page\">Previous <span class=\"sr-only\">page</span></a></li>";
},"3":function(container,depth0,helpers,partials,data) {
    return "<li class=\"page-item disabled\"><span class=\"page-link\" aria-label=\"Previous page\">Previous <span class=\"sr-only\">page</span></span></li>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.is_ellipsis : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + " ";
},"6":function(container,depth0,helpers,partials,data) {
    return "<li class=\"page-item disabled hidden-xs-down\"><span class=\"page-link\" aria-hidden=\"true\"><span aria-hidden=\"true\">&hellip;</span></span></li>";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.is_current : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + " ";
},"9":function(container,depth0,helpers,partials,data) {
    return "<li class=\"page-item active\"><span class=\"page-link\"><span class=\"sr-only\">You're on page </span>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.number : depth0), depth0))
    + "</span></li>";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<li class=\"page-item hidden-xs-down\"><a class=\"page-link\" href=\""
    + alias2(alias1((depth0 != null ? depth0.link : depth0), depth0))
    + "\" aria-label=\"Page "
    + alias2(alias1((depth0 != null ? depth0.number : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.number : depth0), depth0))
    + "</a></li>";
},"13":function(container,depth0,helpers,partials,data) {
    return " &nbsp; ";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<li class=\"page-item\"><a class=\"page-link\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.next : depth0)) != null ? stack1.link : stack1), depth0))
    + "\" aria-label=\"Next page\">Next <span class=\"sr-only\">page</span></a></li>";
},"17":function(container,depth0,helpers,partials,data) {
    return "<li class=\"page-item disabled\"><span class=\"page-link\" aria-label=\"Next page\">Next <span class=\"sr-only\">page</span></span></li>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<nav class=\"text-xs-center\"><ul class=\"pagination\" role=\"navigation\" aria-label=\"Pagination\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.previous : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.pagination : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.next : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "</ul></nav>";
},"useData":true}));
handlebars.registerPartial("result_artist", handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div><span class=\"small text-muted m-l-2\">Songs by</span><h2 class=\"m-l-2\">"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.subtitle : depth0), depth0))
    + "</h2></div>";
},"useData":true}));
handlebars.registerPartial("result_browse", handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div><span class=\"small text-muted m-l-2\">Browsing</span><h2 class=\"m-l-2\">"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.subtitle : depth0), depth0))
    + "</h2></div>";
},"useData":true}));
handlebars.registerPartial("result_search", handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"collapse in\" ";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"collapse\" ";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<option value=\""
    + alias2(alias1((depth0 != null ? depth0.code : depth0), depth0))
    + "\" "
    + alias2(alias1((depth0 != null ? depth0.active : depth0), depth0))
    + ">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</option>";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<option value=\""
    + alias2(alias1((depth0 != null ? depth0.mask : depth0), depth0))
    + "\" "
    + alias2(alias1((depth0 != null ? depth0.active : depth0), depth0))
    + ">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</option>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : {};

  return "<div><span class=\"small text-muted m-l-2\">Search results for</span><h2 class=\"m-l-2\">"
    + alias2(alias1((depth0 != null ? depth0.subtitle : depth0), depth0))
    + "</h2><div class=\"clearfix text-muted m-l-1\"><button class=\"btn btn-link\" type=\"button\" data-toggle=\"collapse\" data-target=\"#song_filters\" aria-expanded=\"false\" aria-controls=\"song_filters\">Filters</button> </div></div><div "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.filtered : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.filtered : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "id=\"song_filters\"><form method=\"get\"><div class=\"row\"><div class=\"col-xs-12 col-sm-8 p-x-0 release-range-datepicker\"><div class=\"col-xs-12\">Release range:</div><div class=\"col-xs-12 col-sm-6 p-y-05\"><div class=\"form-group\"><div class=\"input-group\"><span class=\"input-group-addon\" id=\"release-range-from-addon\">from</span> <input type=\"text\" class=\"form-control\" placeholder=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.available_filters : depth0)) != null ? stack1.released_min : stack1), depth0))
    + "\" aria-describedby=\"release-range-from-addon\" name=\"released_from\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filters : depth0)) != null ? stack1.released_from : stack1), depth0))
    + "\"></div></div></div><div class=\"col-xs-12 col-sm-6 p-y-05\"><div class=\"form-group\"><div class=\"input-group\"><span class=\"input-group-addon\" id=\"release-range-to-addon\">to</span> <input type=\"text\" class=\"form-control\" placeholder=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.available_filters : depth0)) != null ? stack1.released_max : stack1), depth0))
    + "\" aria-describedby=\"release-range-to-addon\" name=\"released_to\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filters : depth0)) != null ? stack1.released_to : stack1), depth0))
    + "\"></div></div></div></div><div class=\"col-xs-12 col-sm-4 p-x-0\"><div class=\"form-group\"><div class=\"col-xs-12\">Languages:<select class=\"form-control show-tick m-y-05\" name=\"languages\" multiple=\"multiple\">"
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.available_filters : depth0)) != null ? stack1.languages : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</select></div></div></div><div class=\"col-xs-12 col-sm-8 p-x-0\"><div class=\"col-xs-12\">Duration, seconds:</div><div class=\"col-xs-12 col-sm-6 p-y-05\"><div class=\"form-group\"><div class=\"input-group\"><span class=\"input-group-addon\" id=\"duration-from-addon\">from</span> <input type=\"text\" class=\"form-control\" placeholder=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.available_filters : depth0)) != null ? stack1.duration_min : stack1), depth0))
    + "\" aria-describedby=\"release-range-from-addon\" name=\"duration_from\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filters : depth0)) != null ? stack1.duration_from : stack1), depth0))
    + "\"></div></div></div><div class=\"col-xs-12 col-sm-6 p-y-05\"><div class=\"form-group\"><div class=\"input-group\"><span class=\"input-group-addon\" id=\"duration-to-addon\">to</span> <input type=\"text\" class=\"form-control\" placeholder=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.available_filters : depth0)) != null ? stack1.duration_max : stack1), depth0))
    + "\" aria-describedby=\"release-range-to-addon\" name=\"duration_to\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filters : depth0)) != null ? stack1.duration_to : stack1), depth0))
    + "\"></div></div></div></div><div class=\"col-xs-12 col-sm-4 p-x-0\"><div class=\"form-group\"><div class=\"col-xs-12\">Genres:<select class=\"form-control show-tick m-y-05\" name=\"genres\" multiple=\"multiple\">"
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.available_filters : depth0)) != null ? stack1.genres : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</select></div></div></div></div><div class=\"row\"><div class=\"col-xs-12 text-xs-right\"><input type=\"hidden\" name=\"search\" value=\""
    + alias2(alias1((depth0 != null ? depth0.search : depth0), depth0))
    + "\"> <button type=\"submit\" class=\"btn btn-primary\">Apply filters</button></div></div></form></div>";
},"useData":true}));
handlebars.registerPartial("song_card", handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"card\" ";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"card card-outline-danger\"";
},"5":function(container,depth0,helpers,partials,data) {
    return " ("
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.movie : depth0), depth0))
    + ")";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.favorite : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "<i class=\"fa fa-heart fa-fw song-heart-label song-heart-label-filled\" aria-hidden=\"true\"></i>";
},"10":function(container,depth0,helpers,partials,data) {
    return "<i class=\"fa fa-heart-o fa-fw song-heart-label song-heart-label-empty\" aria-hidden=\"true\"></i>";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.deleted : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"13":function(container,depth0,helpers,partials,data) {
    return " <span class=\"tag tag-warning\"><i class=\"fa fa-clock-o fa-fw\" title=\"Song will be deleted in one of the later updates\" aria-hidden=\"true\"></i><span class=\"sr-only\">Song will be deleted in one of the later updates</span></span> ";
},"15":function(container,depth0,helpers,partials,data) {
    return " <span class=\"tag tag-danger\"><i class=\"fa fa-times fa-fw\" title=\"Song is not available\" aria-hidden=\"true\" aria-hidden=\"true\"></i><span class=\"sr-only\">Song is not available in your release</span></span> ";
},"17":function(container,depth0,helpers,partials,data) {
    return " <span class=\"tag tag-info\"><i class=\"fa fa-plus fa-fw\" title=\"Song was added in one of the later updates\" aria-hidden=\"true\"></i><span class=\"sr-only\">Song was added in one of the later updates</span></span> ";
},"19":function(container,depth0,helpers,partials,data) {
    return " <span class=\"tag tag-primary\"><i class=\"fa fa-level-up fa-fw\" title=\"Song was updated\" aria-hidden=\"true\"></i><span class=\"sr-only\">Song was updated</span></span> ";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return " <span class=\"tag tag-"
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? depth0.backvocal_label : depth0)) != null ? stack1.color : stack1), depth0))
    + "\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.backvocal_label : depth0)) != null ? stack1.content : stack1), depth0)) != null ? stack1 : "")
    + "</span> ";
},"23":function(container,depth0,helpers,partials,data) {
    return "<div class=\"song-header-handle text-muted nojs-hide\"><i class=\"fa fa-sort\" aria-hidden=\"true\"></i></div>";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : {};

  return "<div class=\"col-xs-6 col-sm-3 push-sm-6 text-xs-right\"><button class=\"btn btn-link p-a-0 song-plus-button nojs-hide\" data-url=\"#song-"
    + alias2(alias1((depth0 != null ? depth0.num : depth0), depth0))
    + "-popover\" title=\"Add to playlist\"><i class=\"fa fa-plus fa-fw\" aria-hidden=\"true\"></i></button><form action=\"/playlist/modify\" method=\"post\" class=\"d-inline\"><input type=\"hidden\" name=\"_csrf\" value=\""
    + alias2(alias1((depth0 != null ? depth0._csrf : depth0), depth0))
    + "\"> <input type=\"hidden\" name=\"song_num\" value=\""
    + alias2(alias1((depth0 != null ? depth0.num : depth0), depth0))
    + "\"> "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.favorite : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data})) != null ? stack1 : "")
    + "</form></div><div class=\"col-xs-12 col-sm-6 pull-sm-3\"><span class=\"tag tag-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model_label : depth0)) != null ? stack1.color : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model_label : depth0)) != null ? stack1.content : stack1), depth0))
    + "</span> "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.backvocal_label : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " <span class=\"tag tag-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.type_label : depth0)) != null ? stack1.color : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.type_label : depth0)) != null ? stack1.content : stack1), depth0))
    + "</span></div>";
},"26":function(container,depth0,helpers,partials,data) {
    return " <button class=\"btn btn-link p-a-0 song-heart-button\" type=\"submit\" name=\"list[remove][i1]\" value=\"1\" title=\"Remove from favorites\"><i class=\"fa fa-heart fa-fw\" aria-hidden=\"true\"></i></button> ";
},"28":function(container,depth0,helpers,partials,data) {
    return " <button class=\"btn btn-link p-a-0 song-heart-button\" type=\"submit\" name=\"list[add][i1]\" value=\"1\" title=\"Add to favorites\"><i class=\"fa fa-heart-o fa-fw\" aria-hidden=\"true\"></i></button> ";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"col-xs-6 col-sm-9\"><span class=\"tag tag-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model_label : depth0)) != null ? stack1.color : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model_label : depth0)) != null ? stack1.content : stack1), depth0))
    + "</span> "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.backvocal_label : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " <span class=\"tag tag-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.type_label : depth0)) != null ? stack1.color : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.type_label : depth0)) != null ? stack1.content : stack1), depth0))
    + "</span></div>";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<form class=\"song-pref-form\" action=\"/preference/modify\" method=\"post\"><input type=\"hidden\" name=\"_csrf\" value=\""
    + alias2(alias1((depth0 != null ? depth0._csrf : depth0), depth0))
    + "\"> <input type=\"hidden\" name=\"song_num\" value=\""
    + alias2(alias1((depth0 != null ? depth0.num : depth0), depth0))
    + "\"> <button class=\"btn btn-link p-a-0 song-pref-button\" type=\"submit\" name=\"tempo_minus\" value=\"1\"><i class=\"fa fa-minus-circle fa-fw\" aria-hidden=\"true\"></i></button> "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},((stack1 = (depth0 != null ? depth0.preferences : depth0)) != null ? stack1.tempo : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(35, data, 0),"data":data})) != null ? stack1 : "")
    + " <button class=\"btn btn-link p-a-0 song-pref-button\" type=\"submit\" name=\"tempo_plus\" value=\"1\"><i class=\"fa fa-plus-circle fa-fw\" aria-hidden=\"true\"></i></button></form>";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return " <strong><span class=\"song-tempo\" style=\"color: red\" data-default=\""
    + alias2(alias1((depth0 != null ? depth0.tempo : depth0), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.preferences : depth0)) != null ? stack1.tempo : stack1), depth0))
    + " ("
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.preferences : depth0)) != null ? stack1.tempo_offset : stack1), depth0))
    + ")</span></strong> ";
},"35":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " <strong><span class=\"song-tempo\" data-default=\""
    + alias2(alias1((depth0 != null ? depth0.tempo : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.tempo : depth0), depth0))
    + "</span></strong> ";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<form class=\"song-pref-form\" action=\"/preference/modify\" method=\"post\"><input type=\"hidden\" name=\"_csrf\" value=\""
    + alias2(alias1((depth0 != null ? depth0._csrf : depth0), depth0))
    + "\"> <input type=\"hidden\" name=\"song_num\" value=\""
    + alias2(alias1((depth0 != null ? depth0.num : depth0), depth0))
    + "\"> <button class=\"btn btn-link p-a-0 song-pref-button\" type=\"submit\" name=\"key_minus\" value=\"1\"><i class=\"fa fa-minus-circle fa-fw\" aria-hidden=\"true\"></i></button> "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},((stack1 = (depth0 != null ? depth0.preferences : depth0)) != null ? stack1.key : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data})) != null ? stack1 : "")
    + " <button class=\"btn btn-link p-a-0 song-pref-button\" type=\"submit\" name=\"key_plus\" value=\"1\"><i class=\"fa fa-plus-circle fa-fw\" aria-hidden=\"true\"></i></button> <strong>("
    + alias2(alias1((depth0 != null ? depth0.voice : depth0), depth0))
    + ")</strong></form>";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return " <strong><span class=\"song-key\" style=\"color: red\" data-default=\""
    + alias2(alias1((depth0 != null ? depth0.raw_key : depth0), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.preferences : depth0)) != null ? stack1.key : stack1), depth0))
    + " ("
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.preferences : depth0)) != null ? stack1.key_offset : stack1), depth0))
    + ")</span></strong> ";
},"40":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " <strong><span class=\"song-key\" data-default=\""
    + alias2(alias1((depth0 != null ? depth0.raw_key : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "</span></strong> ";
},"42":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " <strong><span class=\"song-key\" data-default=\""
    + alias2(alias1((depth0 != null ? depth0.raw_key : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "</span> ("
    + alias2(alias1((depth0 != null ? depth0.voice : depth0), depth0))
    + ")</strong> ";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : {};

  return "<div class=\"row nojs-show\"><hr><div class=\"col-xs-12 p-y-05\">Add to playlist:</div><div class=\"col-xs-12 p-y-05\"><div id=\"song-"
    + alias2(alias1((depth0 != null ? depth0.num : depth0), depth0))
    + "-popover\"><form action=\"/playlist/modify\" method=\"post\"><input type=\"hidden\" name=\"_csrf\" value=\""
    + alias2(alias1((depth0 != null ? depth0._csrf : depth0), depth0))
    + "\"> <input type=\"hidden\" name=\"song_num\" value=\""
    + alias2(alias1((depth0 != null ? depth0.num : depth0), depth0))
    + "\"><ul class=\"song-playlist-list fa-ul\" data-song-num=\""
    + alias2(alias1((depth0 != null ? depth0.num : depth0), depth0))
    + "\">"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.playlists : depth0),{"name":"each","hash":{},"fn":container.program(45, data, 0),"inverse":container.program(50, data, 0),"data":data})) != null ? stack1 : "")
    + "</ul>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.viewer : depth0)) != null ? stack1.can_have_playlists : stack1),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.program(54, data, 0),"data":data})) != null ? stack1 : "")
    + "</form></div></div></div>";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<li>"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.in_playlist : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(48, data, 0),"data":data})) != null ? stack1 : "")
    + "</li>";
},"46":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " <button class=\"btn btn-link p-a-0 song-playlist-toggle\" type=\"submit\" name=\"list[remove][i"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "]\" value=\"1\"><i class=\"fa-li fa fa-check-square\" aria-hidden=\"true\"></i>"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</button> ";
},"48":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " <button class=\"btn btn-link p-a-0 song-playlist-toggle\" type=\"submit\" name=\"list[add][i"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "]\" value=\"1\"><i class=\"fa-li fa fa-square\" aria-hidden=\"true\"></i>"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</button> ";
},"50":function(container,depth0,helpers,partials,data) {
    return "<li>No playlists yet.</li>";
},"52":function(container,depth0,helpers,partials,data) {
    return "<div class=\"input-group\"><label class=\"sr-only\" for=\"new-playlist-title\">New playlist title</label><input type=\"text\" class=\"form-control\" id=\"new-playlist-title\" name=\"title\" placeholder=\"New playlist\" autocomplete=\"off\" value=\"\"> <span class=\"input-group-btn\"><button type=\"submit\" class=\"btn btn-success song-playlist-create\" name=\"list[create]\" value=\"1\"><i class=\"fa fa-plus\" aria-hidden=\"true\" title=\"Create new list\"></i> <span class=\"sr-only\">Create new list</span></button></span></div>";
},"54":function(container,depth0,helpers,partials,data) {
    return "<div><span class=\"text-muted\"><small>You're not allowed to create playlists.</small></span></div>";
},"56":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"pull-xs-left nojs-hide\" data-num=\""
    + alias2(alias1((depth0 != null ? depth0.num : depth0), depth0))
    + "\"><small><a class=\"song-lyrics-button\" href=\"#song-lyrics-"
    + alias2(alias1((depth0 != null ? depth0.num : depth0), depth0))
    + "\">Lyrics</a> <span class=\"song-lyrics-rating\"></span></small></div>";
},"58":function(container,depth0,helpers,partials,data) {
    return "Changes history is not available offline.";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "Last change on "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.last_change : depth0), depth0))
    + "."
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.changes_count : depth0),{"name":"if","hash":{},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"61":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return " Total changes: "
    + alias2(alias1((depth0 != null ? depth0.changes_count : depth0), depth0))
    + " (U: "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.updated : stack1), depth0))
    + ", D: "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.deleted : stack1), depth0))
    + ")";
},"63":function(container,depth0,helpers,partials,data) {
    return "<div class=\"row nojs-hide\"><div id=\"song-lyrics-"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.num : depth0), depth0))
    + "\" class=\"song-lyrics pre-scrollable collapse\"></div></div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.lambda, alias3=container.escapeExpression;

  return "<div "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.available : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.available : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><div class=\"card-header\" role=\"tab\"><div class=\"song-header\"><div class=\"song-header-text\"><a href=\"#song-"
    + alias3(alias2((depth0 != null ? depth0.num : depth0), depth0))
    + "\" data-toggle=\"collapse\" data-parent=\"#song-accordion\" aria-controls=\"song-"
    + alias3(alias2((depth0 != null ? depth0.num : depth0), depth0))
    + "\" aria-expanded=\"false\"><div class=\"row\"><div class=\"song-card-title col-xs-8 font-weight-bold\">"
    + alias3(alias2((depth0 != null ? depth0.title : depth0), depth0))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.movie : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.viewer : depth0)) != null ? stack1.can_have_playlists : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div><div class=\"col-xs-4 text-xs-right\" style=\"color: #f00\">"
    + alias3(alias2((depth0 != null ? depth0.num : depth0), depth0))
    + "</div></div><div class=\"row\"><div class=\"col-xs-8\">"
    + alias3(alias2((depth0 != null ? depth0.artist : depth0), depth0))
    + "</div><div class=\"col-xs-4 text-xs-right\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.available : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.later : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.updated : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.backvocal_label : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " <span class=\"tag tag-"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.type_label : depth0)) != null ? stack1.color : stack1), depth0))
    + "\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.type_label : depth0)) != null ? stack1.content : stack1), depth0))
    + "</span></div></div></a></div>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reorder_allowed : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div></div><div id=\"song-"
    + alias3(alias2((depth0 != null ? depth0.num : depth0), depth0))
    + "\" class=\"collapse\" role=\"tabpanel\"><div class=\"card-block\"><div class=\"row\" style=\"font-weight: bold; font-size: 1.25em\"><div class=\"col-xs-6 col-sm-3\" style=\"color: #f00\">"
    + alias3(alias2((depth0 != null ? depth0.num : depth0), depth0))
    + "</div>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.viewer : depth0)) != null ? stack1.can_have_playlists : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(30, data, 0),"data":data})) != null ? stack1 : "")
    + "</div><div class=\"row\"><div class=\"col-xs-12\" style=\"font-size: 1.5em; font-weight: bold\">"
    + alias3(alias2((depth0 != null ? depth0.title : depth0), depth0))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.movie : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div></div><div class=\"row\"><div class=\"col-xs-12\" style=\"font-size: 1.25em\">"
    + ((stack1 = alias2((depth0 != null ? depth0.artists_formatted : depth0), depth0)) != null ? stack1 : "")
    + "</div></div><div class=\"row\"><div class=\"col-xs-12 col-sm-6\"><div class=\"song-details\"><div class=\"song-details-icon\"><i class=\"fa fa-calendar-check-o fa-fw\"></i></div><div class=\"song-details-text\"><span>Release:</span><br><span><strong>"
    + ((stack1 = alias2((depth0 != null ? depth0.release_formatted : depth0), depth0)) != null ? stack1 : "")
    + "</strong></span></div></div></div><div class=\"col-xs-12 col-sm-6\"><div class=\"song-details\"><div class=\"song-details-icon\"><i class=\"fa fa-music fa-fw\"></i></div><div class=\"song-details-text\"><span>Genres:</span><br><span><strong>"
    + ((stack1 = alias2((depth0 != null ? depth0.genres_formatted : depth0), depth0)) != null ? stack1 : "")
    + "</strong></span></div></div></div></div><div class=\"row\"><div class=\"col-xs-12 col-sm-6\"><div class=\"song-details\"><div class=\"song-details-icon\"><i class=\"fa fa-play fa-fw\"></i></div><div class=\"song-details-text\"><span>Tempo:</span><br>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.viewer : depth0)) != null ? stack1.can_change_preferences : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(35, data, 0),"data":data})) != null ? stack1 : "")
    + "</div></div></div><div class=\"col-xs-12 col-sm-6\"><div class=\"song-details\"><div class=\"song-details-icon\"><i class=\"fa fa-volume-up fa-flip-horizontal fa-fw\"></i></div><div class=\"song-details-text\"><span>Key:</span><br>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.viewer : depth0)) != null ? stack1.can_change_preferences : stack1),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.program(42, data, 0),"data":data})) != null ? stack1 : "")
    + "</div></div></div></div><div class=\"row\"><div class=\"col-xs-12 col-sm-6\"><div class=\"song-details\"><div class=\"song-details-icon\"><i class=\"fa fa-clock-o fa-fw\"></i></div><div class=\"song-details-text\"><span>Duration:</span><br><span><strong>"
    + alias3(alias2((depth0 != null ? depth0.duration : depth0), depth0))
    + "</strong></span></div></div></div><div class=\"col-xs-12 col-sm-6\"><div class=\"song-details\"><div class=\"song-details-icon\"><i class=\"fa fa-globe fa-fw\"></i></div><div class=\"song-details-text\"><span>Languages:</span><br><span><strong>"
    + ((stack1 = alias2((depth0 != null ? depth0.languages_formatted : depth0), depth0)) != null ? stack1 : "")
    + "</strong></span></div></div></div></div>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.viewer : depth0)) != null ? stack1.can_have_playlists : stack1),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div><div class=\"card-footer\"><div class=\"row\"><div class=\"col-xs-12\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.viewer : depth0)) != null ? stack1.can_view_lyrics : stack1),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"pull-xs-right\"><small class=\"text-muted\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.offline : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.program(60, data, 0),"data":data})) != null ? stack1 : "")
    + "</small></div></div></div>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.viewer : depth0)) != null ? stack1.can_view_lyrics : stack1),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div></div></div>";
},"useData":true})); module.exports = handlebars;