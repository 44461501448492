var Handlebars = require("handlebars-wrapper/partials");module.exports = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"row p-y-05 nojs-show\"><div class=\"col-xs-12 offset-md-3 col-md-6\"><div class=\"alert alert-"
    + alias2(alias1((depth0 != null ? depth0.alert_type : depth0), depth0))
    + "\" role=\"alert\">"
    + alias2(alias1((depth0 != null ? depth0.message : depth0), depth0))
    + "</div></div></div>";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<option value=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" "
    + alias2(alias1((depth0 != null ? depth0.compare_active : depth0), depth0))
    + ">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</option>";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<option value=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" "
    + alias2(alias1((depth0 != null ? depth0.active : depth0), depth0))
    + ">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</option>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"container\">"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.messages : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<form class=\"user-settings-form\" method=\"post\" action=\"/settings\"><div class=\"row\"><div class=\"col-xs-12 offset-md-3 col-md-6\"><div class=\"form-group\"><label class=\"col-form-label\" for=\"settings-username\">Username:</label><input type=\"text\" class=\"form-control\" placeholder=\"Username\" id=\"settings-username\" name=\"username\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.username : stack1), depth0))
    + "\" disabled=\"disabled\"> <small class=\"form-text text-muted\">This is used in links to your stuff. Only latin letters, numbers and _ are allowed.</small></div></div></div><div class=\"row\"><div class=\"col-xs-12 offset-md-3 col-md-6\"><div class=\"form-group\"><label class=\"col-form-label\" for=\"settings-email\">E-mail:</label><input type=\"text\" class=\"form-control\" placeholder=\"E-mail\" id=\"settings-email\" name=\"email\" type=\"email\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.email : stack1), depth0))
    + "\" disabled=\"disabled\"> <small class=\"form-text text-muted\">Only for important staff, for example, if you'll somehow forget your password.</small></div></div></div><div class=\"row\"><div class=\"col-xs-12 offset-md-3 col-md-6\"><div class=\"form-group\"><label class=\"col-form-label\" for=\"settings-compare-release\">Release to compare:</label><select id=\"settings-compare-release\" class=\"form-control\" name=\"compare_release\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.offline : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.releases : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</select><small class=\"form-text text-muted\">\"I have this release on my device\" option. All information will be compared against this release.</small></div></div></div><div class=\"row\"><div class=\"col-xs-12 offset-md-3 col-md-6\"><div class=\"form-group\"><label class=\"col-form-label\" for=\"settings-show-device\">Device model:</label><select id=\"settings-show-device\" class=\"form-control\" name=\"show_device\">"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.device_models : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</select><small class=\"form-text text-muted\">Choose a device model you want to show songs for. AST-100 model supports all songs for AST-50.</small></div></div></div><div class=\"row\"><div class=\"col-xs-12 offset-md-3 col-md-6\"><div class=\"form-group\"><label class=\"col-form-label\" for=\"settings-songs-per-page\">Songs per page (max "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.settings_allowed : depth0)) != null ? stack1.songs_per_page_max : stack1), depth0))
    + "):</label><input type=\"text\" class=\"form-control\" placeholder=\"Songs per page\" id=\"settings-songs-per-page\" name=\"songs_per_page\" type=\"number\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.songs_per_page : stack1), depth0))
    + "\"> </div></div></div><div class=\"row\"><div class=\"col-xs-12 offset-md-3 col-md-6\"><div class=\"form-group\"><label class=\"col-form-label\" for=\"settings-artists-per-page\">Artists per page (max "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.settings_allowed : depth0)) != null ? stack1.artists_per_page_max : stack1), depth0))
    + "):</label><input type=\"text\" class=\"form-control\" placeholder=\"Artists per page\" id=\"settings-artists-per-page\" name=\"artists_per_page\" type=\"number\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.artists_per_page : stack1), depth0))
    + "\"> </div></div></div><div class=\"row p-y-05\"><div class=\"col-xs-6 offset-md-3 col-md-3 text-xs-left\"><a class=\"btn btn-outline-success disabled\" role=\"button\">Back</a></div><div class=\"col-xs-6 col-md-3 text-xs-right\"><button class=\"btn btn-success\" type=\"submit\">Save</button></div></div></form></div>";
},"useData":true});