'use strict'

// server/views/user/profile.js

var functions = require('../../../lib/functions.client.js');

var tmpl = {
	profile: require('../../templates/user/profile'),
	navbar: require('../../templates/navbar'),
	footer: require('../../templates/footer'),
};

module.exports = function profile( model ) {
	model.menu_items = functions.getMenuItems( model.viewer, model.route.action );
	model.messages = functions.getMessagesArray( model.messages );

	if( model.owner.playlists ) {
		model.owner.playlists = model.owner.playlists.map(function(playlist) {
			return {
				title: playlist.title,
				link: taunus.resolve( 'user/playlist/songs', {
					username: model.owner.username,
					list: playlist.id,
				}),
				songs_count: playlist.songs_count
			};
		});
	}

	if( model.owner.stats ) {
		if( model.owner.stats.favorite_artist ) {
			//model.owner.stats.favorite_artist_link = '<a href="/artist/'+encodeURIComponent(model.owner.stats.favorite_artist)+'/songs">'+model.owner.stats.favorite_artist+'</a>';
			model.owner.stats.favorite_artist_link = '<a href="'+ taunus.resolve('artist/songs', { artist: model.owner.stats.favorite_artist }) +'">'+model.owner.stats.favorite_artist+'</a>'
		}

		if( model.owner.stats.favorite_language ) {
			model.owner.stats.favorite_language = functions.getFullLanguageFromISO( model.owner.stats.favorite_language );
		}

		if( model.owner.stats.average_tempo ) {
			model.owner.stats.average_tempo = Math.round( model.owner.stats.average_tempo );
		}
	}

	return tmpl.navbar( model ) + '<main>' + tmpl.profile( model ) + '</main>' + tmpl.footer( model );
}