'use strict';

// client/controllers/user/settings.js

var songs_per_transaction = 1000;

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

//var functions = require('../../../lib/functions.client.js');

module.exports = function( model, container, route ) {
	var _container = $(container);
	var username = window.localStorage.getItem( 'username' );

	if( username ) {
		if( !model.offline ) {
			console.log( '[offline/settings] updating offline settings with values stored on the server' );

			window.localStorage.setItem( 'settings.'+username, JSON.stringify( model.settings ) );
			window.localStorage.setItem( 'settings_allowed.'+username, JSON.stringify( model.settings_allowed ) );
		}

		_container.find('form.user-settings-form').on('submit', function(ev) {
			if( Offline.state != 'up' )
				ev.preventDefault();

			var previous_settings = JSON.parse( window.localStorage.getItem( 'settings.'+username ) );

			if( Offline.state != 'up' ) {
				var new_compare_release = previous_settings.compare_release || 0;
			} else {
				var new_compare_release = parseInt( _container.find('select[name="compare_release"]').first().val(), 10 );
			}

			var new_settings = {
				username: previous_settings.username,
				username: previous_settings.display_name,
				email: previous_settings.email,
				artists_per_page: parseInt( _container.find('input[name="artists_per_page"]').first().val(), 10 ),
				compare_release: new_compare_release,
				show_device: parseInt( _container.find('select[name="show_device"]').first().val(), 10 ),
				songs_per_page: parseInt( _container.find('input[name="songs_per_page"]').first().val(), 10 ),
			};

			window.localStorage.setItem( 'settings.'+username, JSON.stringify( new_settings ) );
		});
	}
}