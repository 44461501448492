'use strict';

var templates = {
  'artist/list': require('../server/views/artist/list.js'),
  'home': require('../server/views/home.js'),
  'notifier': require('../server/views/notifier.js'),
  'show_error': require('../server/views/show_error.js'),
  'song/list': require('../server/views/song/list.js'),
  'song/preferences': require('../server/views/song/preferences.js'),
  'user/email_confirmation': require('../server/views/user/email_confirmation.js'),
  'user/login': require('../server/views/user/login.js'),
  'user/logout': require('../server/views/user/logout.js'),
  'user/offline': require('../server/views/user/offline.js'),
  'user/playlist/songs': require('../server/views/user/playlist/songs.js'),
  'user/profile': require('../server/views/user/profile.js'),
  'user/registration': require('../server/views/user/registration.js'),
  'user/settings': require('../server/views/user/settings.js')
};

var controllers = {
  'song/list': require('./controllers/song/list.js'),
  'user/offline': require('./controllers/user/offline.js'),
  'user/playlist/songs': require('./controllers/user/playlist/songs.js'),
  'user/settings': require('./controllers/user/settings.js')
};

var routes = [
  {
    route: '/',
    action: 'home'
  },
  {
    route: '/songs',
    action: 'song/list'
  },
  {
    route: '/artists',
    action: 'artist/list'
  },
  {
    route: '/artist/:artist/songs',
    action: 'artist/songs'
  },
  {
    route: '/search',
    action: 'song/search'
  },
  {
    route: '/registration',
    action: 'user/registration'
  },
  {
    route: '/email_confirmation',
    action: 'user/email_confirmation'
  },
  {
    route: '/login',
    action: 'user/login'
  },
  {
    route: '/logout',
    action: 'user/logout'
  },
  {
    route: '/settings',
    action: 'user/settings'
  },
  {
    route: '/offline',
    action: 'user/offline'
  },
  {
    route: '/user/:username',
    action: 'user/profile'
  },
  {
    route: '/user/:username/playlist/:list',
    action: 'user/playlist/songs'
  },
  {
    route: '/user/:username/favorites',
    action: 'user/playlist/songs'
  },
  {
    route: '/preference/modify',
    action: 'user/preference/modify',
    ignore: true
  },
  {
    route: '/playlist/modify',
    action: 'user/playlist/modify',
    ignore: true
  },
  {
    route: '/playlist/confirm',
    action: 'user/playlist/confirm'
  },
  {
    route: '/song/lyrics',
    action: 'song/lyrics',
    ignore: true
  },
  {
    route: '/offline/export',
    action: 'offline/export',
    ignore: true
  },
  {
    route: '/offline/sync',
    action: 'offline/sync',
    ignore: true
  },
  {
    route: '/offline/check',
    action: 'offline/check',
    ignore: true
  },
  {
    route: '/admin/:category/:action',
    action: 'admin'
  },
  {
    route: '/admin/:category',
    action: 'admin'
  },
  {
    route: '/admin',
    action: 'admin'
  },
  {
    route: '*',
    action: 'show_error'
  }
];

var deferrals = [
  'admin',
  'offline',
  'user/playlist/confirm'
];

module.exports = {
  templates: templates,
  controllers: controllers,
  routes: routes,
  deferrals: deferrals
};
