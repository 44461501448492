var Handlebars = require("handlebars-wrapper/partials");module.exports = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"row p-y-1 nojs-show\"><div class=\"col-xs-12 offset-md-3 col-md-6\"><div class=\"alert alert-"
    + alias2(alias1((depth0 != null ? depth0.alert_type : depth0), depth0))
    + "\" role=\"alert\">"
    + alias2(alias1((depth0 != null ? depth0.message : depth0), depth0))
    + "</div></div></div>";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<form action=\"/playlist/confirm\" method=\"get\"><input type=\"hidden\" name=\"list_id\" value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.list_id : depth0), depth0))
    + "\"> <button class=\"btn btn-link p-a-0 m-x-1 playlist-clear-button\" type=\"submit\" name=\"clear\" value=\"1\" title=\"Clear this playlist\"><i class=\"fa fa-recycle fa-fw\" aria-hidden=\"true\"></i>Clear<span class=\"sr-only\"> this playlist</span></button> "
    + ((stack1 = helpers.unless.call(alias1,helpers.equals.call(alias1,(depth0 != null ? depth0.list_id : depth0),1,{"name":"equals","hash":{},"data":data}),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</form>";
},"4":function(container,depth0,helpers,partials,data) {
    return " <button class=\"btn btn-link p-a-0 m-x-1 playlist-delete-button\" type=\"submit\" name=\"delete\" value=\"1\" title=\"Delete this playlist\"><i class=\"fa fa-trash-o fa-fw\" aria-hidden=\"true\"></i>Delete<span class=\"sr-only\"> this playlist</span></button> ";
},"6":function(container,depth0,helpers,partials,data) {
    return "class=\"reorder\" ";
},"8":function(container,depth0,helpers,partials,data) {
    return "data-playlist-id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.list_id : depth0), depth0))
    + "\" ";
},"10":function(container,depth0,helpers,partials,data) {
    return "data-playlist-starting-position=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.starting_position : depth0), depth0))
    + "\" ";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(partials.song_card,depth0,{"name":"song_card","hash":{"_csrf":(depths[1] != null ? depths[1]._csrf : depths[1]),"reorder_allowed":(depths[1] != null ? depths[1].reorder_allowed : depths[1]),"offline":(depths[1] != null ? depths[1].offline : depths[1]),"viewer":(depths[1] != null ? depths[1].viewer : depths[1])},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " ";
},"14":function(container,depth0,helpers,partials,data) {
    return "<div class=\"card text-xs-center\"><div class=\"card-header\"><div class=\"row\"><div class=\"col-xs-12\"><strong>No songs in this playlist.</strong></div></div></div><div class=\"card-block\"><div class=\"row\"><div class=\"col-xs-12\">Sorry.</div></div></div></div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"container\">"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.messages : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div><span class=\"small text-muted m-l-2\">Playlist</span><h2 class=\"m-l-2\">"
    + alias3(alias2((depth0 != null ? depth0.subtitle : depth0), depth0))
    + "</h2><div class=\"clearfix text-muted m-l-1\"><div class=\"pull-xs-left m-x-1\" title=\"Owner\"><i class=\"fa fa-user fa-fw\" aria-hidden=\"true\"></i><span class=\"sr-only\">Owner: </span>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.username : stack1), depth0))
    + "</div><div class=\"pull-xs-left m-x-1 duration-popup\"><i class=\"fa fa-clock-o fa-fw\" aria-hidden=\"true\"></i><span class=\"sr-only\">Playlist duration: </span>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.totals : depth0)) != null ? stack1.duration : stack1), depth0))
    + "</div>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.viewer : depth0)) != null ? stack1.is_owner : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div></div><div id=\"song-accordion\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reorder_allowed : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "role=\"tablist\" aria-multiselectable=\"false\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reorder_allowed : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reorder_allowed : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "data-csrf=\""
    + alias3(alias2((depth0 != null ? depth0._csrf : depth0), depth0))
    + "\">"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.songs : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</div>"
    + ((stack1 = container.invokePartial(partials.pagination,(depth0 != null ? depth0.pages : depth0),{"name":"pagination","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true,"useDepths":true});