'use strict';

// server/views/home.js

var functions = require('../../lib/functions.client.js');

var tmpl = {
	home:   require('../templates/home'),
	navbar: require('../templates/navbar'),
	footer: require('../templates/footer'),
};

module.exports = function( model ) {
	model.menu_items = functions.getMenuItems( model.viewer, model.route.action );
	model.messages = functions.getMessagesArray( model.messages );

	return tmpl.navbar( model ) + '<main>' + tmpl.home( model ) + '</main>' + tmpl.footer( model );
}