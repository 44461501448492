var Handlebars = require("handlebars-wrapper/partials");module.exports = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : {};

  return "<div class=\"card\"><div class=\"card-header\"><div class=\"row\"><div class=\"col-xs-8\">"
    + ((stack1 = alias1((depth0 != null ? depth0.link : depth0), depth0)) != null ? stack1 : "")
    + "</div><div class=\"col-xs-4 text-xs-right\">"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.available : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.later : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias2,((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.updated : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " <span class=\"tag tag-primary\"><span class=\"sr-only\">Songs available: </span>"
    + container.escapeExpression(alias1((depth0 != null ? depth0.songs_count : depth0), depth0))
    + "</span></div></div></div></div>";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.deleted : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"3":function(container,depth0,helpers,partials,data) {
    return " <span class=\"tag tag-warning\"><i class=\"fa fa-clock-o fw\" title=\"No songs by this artist in the latest update\" aria-hidden=\"true\"></i><span class=\"sr-only\">No songs by this artist in the latest update</span></span> ";
},"5":function(container,depth0,helpers,partials,data) {
    return " <span class=\"tag tag-danger\"><i class=\"fa fa-times fw\" title=\"Songs by this artist are not available in your update\" aria-hidden=\"true\" aria-hidden=\"true\"></i><span class=\"sr-only\">Songs by this artist are not available in your update</span></span> ";
},"7":function(container,depth0,helpers,partials,data) {
    return " <span class=\"tag tag-info\"><i class=\"fa fa-plus fw\" title=\"Songs by this artist will be added in later updates\" aria-hidden=\"true\"></i><span class=\"sr-only\">Songs by this artist will be added in later updates</span></span> ";
},"9":function(container,depth0,helpers,partials,data) {
    return " <span class=\"tag tag-primary\"><i class=\"fa fa-level-up fw\" title=\"Artist's songs will be updated\" aria-hidden=\"true\"></i><span class=\"sr-only\">Artist's songs will be updated</span></span> ";
},"11":function(container,depth0,helpers,partials,data) {
    return "<div class=\"card text-xs-center\"><div class=\"card-header\"><div class=\"row\"><div class=\"col-xs-12\"><strong>No artists found.</strong></div></div></div></div>";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<ul class=\"pagination\" role=\"navigation\" aria-label=\"Pagination\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.previous : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.pagination : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(26, data, 0),"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.next : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data})) != null ? stack1 : "")
    + "</ul>";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<li class=\"page-item\"><a class=\"page-link\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.previous : depth0)) != null ? stack1.link : stack1), depth0))
    + "\" aria-label=\"Previous page\">Previous <span class=\"sr-only\">page</span></a></li>";
},"16":function(container,depth0,helpers,partials,data) {
    return "<li class=\"page-item disabled\"><span class=\"page-link\" aria-label=\"Previous page\">Previous <span class=\"sr-only\">page</span></span></li>";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.is_ellipsis : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + " ";
},"19":function(container,depth0,helpers,partials,data) {
    return "<li class=\"page-item disabled hidden-xs-down\"><span class=\"page-link\" aria-hidden=\"true\"><span aria-hidden=\"true\">&hellip;</span></span></li>";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.is_current : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data})) != null ? stack1 : "")
    + " ";
},"22":function(container,depth0,helpers,partials,data) {
    return "<li class=\"page-item active\"><span class=\"page-link\"><span class=\"sr-only\">You're on page </span>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.number : depth0), depth0))
    + "</span></li>";
},"24":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<li class=\"page-item hidden-xs-down\"><a class=\"page-link\" href=\""
    + alias2(alias1((depth0 != null ? depth0.link : depth0), depth0))
    + "\" aria-label=\"Page "
    + alias2(alias1((depth0 != null ? depth0.number : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.number : depth0), depth0))
    + "</a></li>";
},"26":function(container,depth0,helpers,partials,data) {
    return " &nbsp; ";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<li class=\"page-item\"><a class=\"page-link\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.next : depth0)) != null ? stack1.link : stack1), depth0))
    + "\" aria-label=\"Next page\">Next <span class=\"sr-only\">page</span></a></li>";
},"30":function(container,depth0,helpers,partials,data) {
    return "<li class=\"page-item disabled\"><span class=\"page-link\" aria-label=\"Next page\">Next <span class=\"sr-only\">page</span></span></li>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"container\"><div id=\"song-accordion\">"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.artists : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "</div><nav class=\"text-xs-center\">"
    + ((stack1 = helpers["with"].call(alias1,(depth0 != null ? depth0.pages : depth0),{"name":"with","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</nav></div>";
},"useData":true});