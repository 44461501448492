'use strict';

// client/controllers/song/list.js

var functions = require('../../../lib/functions.client.js');

module.exports = function( model, container, route ) {
	if( 'viewer' in model )
		if( model.viewer.username )
			window.localStorage.setItem('username', model.viewer.username );

	functions.animateSongList( container );

	return false;
};
