// helpers.js
var safeson = require('safeson');

module.exports = function( handlebars ) {
	handlebars.registerHelper('taunus_inline', function( taunus_obj ) {
		return safeson({ version: taunus_obj.version, model: taunus_obj.model });
	});

	handlebars.registerHelper('equals', function( a, b ) {
		return ( a == b );
	});

	handlebars.registerHelper('or', function( a, b ) {
		return ( a || b );
	});

	return handlebars;
}