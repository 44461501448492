// server/views/notifier.js

var functions = require('../../lib/functions.client.js');

var tmpl = {
	notifier: require('../templates/notifier'),
	navbar: require('../templates/navbar'),
	footer: require('../templates/footer'),
};

module.exports = function( model ) {
	model.messages = functions.getMessagesArray( model.messages );

	model.menu_items = functions.getMenuItems( model );

	return tmpl.navbar( model ) + '<main>' + tmpl.notifier( model ) + '</main>' + tmpl.footer( model );
}