'use strict'

// server/views/user/settings.js

var functions = require('../../../lib/functions.client.js');

var tmpl = {
	settings: require('../../templates/user/settings'),
	navbar: require('../../templates/navbar'),
	footer: require('../../templates/footer'),
};

var _months = ['January','February','March','April','May','June','July','August','September','October','November','December'];

module.exports = function settings( model ) {
	if( model.settings_allowed.available_releases ) {
		model.releases = [];

		for( var index = 0; index < model.settings_allowed.available_releases.length; index++ ) {
			var release_short = Math.floor( model.settings_allowed.available_releases[index].id / 100 );
			if( model.settings_allowed.available_releases[index].id == 0 ) {
				var name = 'Always the latest';
			} else {
				var name = ''+_months[Math.abs( Math.floor( release_short % 100 ) - 1 )]+' '+Math.floor( release_short / 100 );
			}

			if( model.settings_allowed.available_releases[index].actual )
				name += ' (+)';

			model.releases.push({
				id: model.settings_allowed.available_releases[index].id,
				name: name,
				compare_active: (model.settings.compare_release == model.settings_allowed.available_releases[index].id)? 'selected': ''
			});
		}
	} else {
		console.error( 'no available_releases for some reason' );
		model.releases = [{ id: 0, name: 'Error while fetching available releases', compare_active: 'selected' }];
	}

	model.device_models = [
		{ id: 0, name: 'AST-100', active: (model.settings.show_device == 0)? 'selected': '' },
		{ id: 1, name: 'AST-50',  active: (model.settings.show_device == 1)? 'selected': '' },
	];

	model.menu_items = functions.getMenuItems( model.viewer, model.route.action );
	model.messages = functions.getMessagesArray( model.messages );

	return tmpl.navbar( model ) + '<main>' + tmpl.settings( model ) + '</main>' + tmpl.footer( model );
}