var Handlebars = require("handlebars-wrapper/partials");module.exports = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"row p-y-05 nojs-show\"><div class=\"col-xs-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6\"><div class=\"alert alert-"
    + alias2(alias1((depth0 != null ? depth0.alert_type : depth0), depth0))
    + "\" role=\"alert\">"
    + alias2(alias1((depth0 != null ? depth0.message : depth0), depth0))
    + "</div></div></div>";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : {};

  return "<div class=\"row p-y-05\"><div class=\"col-xs-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6\"><h2>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.display_name : stack1), depth0))
    + " <span class=\"tag tag-primary\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.group : stack1), depth0))
    + "</span></h2></div></div><div class=\"row p-y-05\"><div class=\"col-xs-6 offset-md-2 col-md-4 offset-lg-3 col-lg-3\">Username:</div><div class=\"col-xs-6 col-md-4 col-lg-3\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.username : stack1), depth0))
    + "</div></div>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.email : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.registered : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.stats : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " <div class=\"row p-y-05\"><div class=\"col-xs-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6\"><div class=\"list-group\">"
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.playlists : stack1),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "</div></div></div>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.own_profile : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row p-y-05\"><div class=\"col-xs-6 offset-md-2 col-md-4 offset-lg-3 col-lg-3\">E-mail:</div><div class=\"col-xs-6 col-md-4 col-lg-3\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.email : stack1), depth0))
    + "</div></div>";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row p-y-05\"><div class=\"col-xs-6 offset-md-2 col-md-4 offset-lg-3 col-lg-3\">Registration:</div><div class=\"col-xs-6 col-md-4 col-lg-3\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.registered : stack1), depth0))
    + "</div></div>";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.favorite_artist : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.favorite_language : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.average_tempo : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row p-y-05\"><div class=\"col-xs-6 offset-md-2 col-md-4 offset-lg-3 col-lg-3\">Most songs in playlists by:</div><div class=\"col-xs-6 col-md-4 col-lg-3\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.favorite_artist_link : stack1), depth0)) != null ? stack1 : "")
    + "</div></div>";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row p-y-05\"><div class=\"col-xs-6 offset-md-2 col-md-4 offset-lg-3 col-lg-3\">Most songs in playlists are in:</div><div class=\"col-xs-6 col-md-4 col-lg-3\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.favorite_language : stack1), depth0))
    + "</div></div>";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row p-y-05\"><div class=\"col-xs-6 offset-md-2 col-md-4 offset-lg-3 col-lg-3\">Average tempo of the songs:</div><div class=\"col-xs-6 col-md-4 col-lg-3\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.average_tempo : stack1), depth0))
    + "</div></div>";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " <a href=\""
    + alias2(alias1((depth0 != null ? depth0.link : depth0), depth0))
    + "\" class=\"list-group-item list-group-item-action\"><span class=\"tag tag-default tag-pill pull-xs-right\">"
    + alias2(alias1((depth0 != null ? depth0.songs_count : depth0), depth0))
    + "</span>"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</a> ";
},"17":function(container,depth0,helpers,partials,data) {
    return " <a href=\"#\" class=\"list-group-item list-group-item-action disabled\">none</a> ";
},"19":function(container,depth0,helpers,partials,data) {
    return "<div class=\"row p-y-05\"><div class=\"col-xs-6 offset-md-2 col-md-4 offset-lg-3 col-lg-3 text-xs-left\"><a href=\"/logout\" class=\"btn btn-outline-secondary\" role=\"button\">Logout</a></div><div class=\"col-xs-6 col-md-4 col-lg-3 text-xs-right\"><a href=\"/settings\" class=\"btn btn-success\" role=\"button\">Settings</a></div></div>";
},"21":function(container,depth0,helpers,partials,data) {
    return "<div class=\"row p-y-05\"><div class=\"col-xs-12 text-xs-center\">User not found.</div></div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"container\">"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.messages : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.owner : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});