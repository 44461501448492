'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var Dexie = (typeof window !== "undefined" ? window['Dexie'] : typeof global !== "undefined" ? global['Dexie'] : null);
var _db;
var _username;

function _getDexie( indexedDB, username ) {
	Dexie.debug = true;

	var db = new Dexie( 'SongsDatabase.' + username, {
		autoOpen: false,
		indexedDB: indexedDB, // or the shim's version
		IDBKeyRange: window.IDBKeyRange, // or the shim's version.
	} );

	db.version(1).stores({
		songs: "num,artist,*artists,title,release,genre,*languages,duration",
		playlists: "id,*songs",
		songs_index: "word",
		artists: "name",
	});

	return db.open();
}

module.exports = function( username ) {
	// checking if launched in the browser
	if( !window )
		return;

	if( username == null ) {
		username = window.localStorage.getItem( 'username' );
	}

	if( (_db != null) && (username == _username) ) {
		return _db.open(); // this should always return a promise
	}

	if( (_db == null) && (username != null) ) {
		var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;

		if( indexedDB ) {
			return _getDexie( indexedDB, username ).then(function( db ) {
				_db = db;
				_username = username;

				return _db;
			});
		} else {
			if( window.shimIndexedDB ) {
				return _getDexie( window.shimIndexedDB, username ).then(function( db ) {
					_db = db;
					_username = username;

					return _db;
				});
			} else {
				return null;
			}
		}
	} else {
		return;
	}
};