'use strict'

// server/views/user/login.js

var functions = require('../../../lib/functions.client.js');

var tmpl = {
	login: require('../../templates/user/login'),
	navbar: require('../../templates/navbar'),
	footer: require('../../templates/footer'),
};

module.exports = function login( model ) {
	model.menu_items = functions.getMenuItems( model.viewer, model.route.action );
	model.messages = functions.getMessagesArray( model.messages );

	return tmpl.navbar( model ) + '<main>' + tmpl.login( model ) + '</main>' + tmpl.footer( model );
}