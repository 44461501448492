'use strict';

// client/controllers/user/offline.js

var songs_per_transaction = 1000;

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var functions = require('../../../lib/functions.client.js');
var getDexie = require('songs_db')();

var _status_block_timeout;
var valid_offline_modes = {
	'disabled': 'Disabled',
	'basic': 'Basic',
	'full': 'Full',
	'forced': 'Forced',
};

function ajaxPromise( options ) {
	return new Dexie.Promise(function( resolve, reject ) {
		$.ajax(options)
			.done(function( data ) {
				resolve( data );
			})
			.fail(function( jqXHR, textStatus, errorThrown ) {
				if( typeof errorThrown == 'string' )
					reject( new Error( errorThrown ) );
				else
					reject( errorThrown );
			});
	});
}

function _show_progress( container, status ) {
	var status_block = container.find('.offline-status-block');
	status_block.find('.offline-status').removeClass('text-danger text-success').text( status );
	status_block.find('.offline-progress').attr('value', 0);
	status_block.find('.offline-progress').find('.progress-bar').css('width', ''+0+'%').text(''+0+'%');
	clearTimeout( _status_block_timeout );
	status_block.slideDown('fast');
}

function _report_progress( container, progress, status ) {
	if( status ) {
		container.find('.offline-status').removeClass('text-danger text-success').text( status );
	}

	var progressbar = container.find('.offline-progress');
	var percent = Math.round( progress * 100 );
	if( percent > 100 ) percent = 100;
	progressbar.attr( 'value', percent );
	progressbar.find('.progress-bar').css('width', ''+percent+'%').text(''+percent+'%');
}

function _fail_progress( container, status ) {
	clearTimeout( _status_block_timeout );

	container.find('.offline-status').addClass('text-danger').text( status );
}

function _done_progress( container, status ) {
	// just in case, to prevent possible surprising behaviour
	clearTimeout( _status_block_timeout );

	container.find('.offline-status').addClass('text-success').text( status );
	_status_block_timeout = setTimeout( function() { container.find('.offline-status-block').slideUp('fast'); }, 5000 );
}

function _getPlaylistStats( db ) {
	return db.playlists.where(':id').above(0).count().then(function(pls_count) {
		if( pls_count > 0 ) {
			return db.playlists.orderBy(':id').reverse().limit(1).toArray().then(function(pls_max) {
				return {
					count: pls_count,
					max_id: pls_max[0].id,
				};
			});
		} else {
			return {
				count: 0,
				max_id: 0,
			};
		}
	});
}

function _check_updates( db, device_id ) {
	return _getPlaylistStats( db ).then(function( pls_stats ) {
		return ajaxPromise({
			url: '/offline/sync?type=check',
			method: 'POST',
			data: {
				device_id: device_id,
				playlists: pls_stats,
			},
			dataType: 'json',
		}).then(function(data){
			return data.stats;
		});
	});
}

function _download_offline_data( db, container, params, skip, total_songs ) {
	console.log( '[api/export] sending request to export songs starting @ %d (total: %d)', skip, total_songs );

	params['skip'] = skip;

	return ajaxPromise({
		url: '/offline/export',
		method: 'GET',
		data: params,
		dataType: 'json',
	})
	.then(function( data ) {
		console.log( '[offline] %d songs received', data.songs.length );

		data.songs = data.songs.map(function(song) {
			if( song.artists ) {
				song.artist = song.artists.join(' & ');
			} else {
				console.log(song);
			}

			return song;
		});

		return db.songs.bulkPut(data.songs).then(function() {
			console.log( '[offline] %d songs added to db', data.songs.length );

			return db.songs.count();
		}).then(function(songs_count) {
			console.log('[offline] there are %d songs in db', songs_count);

			if( total_songs > 0 ) {
				_report_progress( container, (skip + songs_per_transaction) / total_songs );

				if( ( total_songs - skip ) < songs_per_transaction ) {
					return;
				} else {
					return _download_offline_data( db, container, params, (skip + songs_per_transaction), total_songs );
				}
			} else {
				if( data.songs.length < songs_per_transaction ) {
					_report_progress( container, 1.0 );

					return;
				} else {
					_report_progress( container, 0.5 );

					return _download_offline_data( db, container, params, (skip + songs_per_transaction), 0 );
				}
			}
		});
	});
}

function _download_all_songs( db, container, username, total_songs ) {
	return _download_offline_data( db, container, { 'type': 'full' }, 0, total_songs).then(function() {
		return ajaxPromise({
			'url': '/offline/check',
			'method': 'GET',
			'data': { 'last_update': 0 },
		})
		.then(function(data) {
			window.localStorage.setItem( 'offline_release.'+username, window.localStorage.getItem( 'compare_release.'+username ) );
			window.localStorage.setItem( 'offline_version.'+username, data.last_update );
			window.localStorage.setItem( 'last_version.'+username, data.last_update );
			window.localStorage.setItem( 'last_version_check.'+username, Date.now() );

			_done_progress( container, 'Songs have been downloaded successfully!' );

			console.log('[offline] all songs have been downloaded successfully');
		});
	});
}

function _get_device_id( ) {
	var device_id = window.localStorage.getItem('device_id');

	// if we already have some kind of ID
	if( device_id )
		return Dexie.Promise.resolve( device_id );

	return ajaxPromise({
		url: '/offline/sync',
		method: 'GET',
		data: {
			type: 'get_device_id'
		},
		dataType: 'json',
	})
	.then(function( data ) {
		window.localStorage.setItem( 'device_id', data.device_id );

		return data.device_id;
	});
}

function _download_playlists( db, container, device_id, playlists ) {
	console.log( '[api/export] sending request to export %s playlists', (playlists.length)?playlists.length:'all' );

	return ajaxPromise({
		url: '/offline/sync',
		method: 'GET',
		data: {
			type: 'export',
			device_id: device_id,
			playlists: playlists
		},
		dataType: 'json',
	})
	.then(function( data ) {
		console.log( '[offline] %d playlists received', data.playlists.length );

		window.localStorage.setItem( 't1c', ( Date.now() / 1000 ) | 0 );

		// TODO: playlist preprocessing?

		return db.playlists.bulkPut( data.playlists );
	});
}

function _create_index( db, container, total_songs ) {
	var index = {};
	var available_languages = {};
	var released_min, released_max, duration_min, duration_max;

	var songs_processed = 0;
	return db.songs.orderBy('num').each(function(song) {
		var words = functions.getTermsFromString( song.artist + ' ' + song.title + ' ' + song.movie );

		for( var word_index = 0; word_index < words.length; word_index++ ) {
			//console.log( 'got word: %s', words[word_index] );
			if( index[words[word_index]] ) {
				//console.log( 'modifiying index: %s', words[word_index] );
				if( index[words[word_index]].songs ) {
					index[words[word_index]].songs = [song.num].concat( index[words[word_index]].songs );
				} else {
					index[words[word_index]].songs = index[words[word_index]].songs;
				}
			} else {
				//console.log( 'adding index: %s (initiating with song %d)', words[word_index], song.num );
				index[words[word_index]] = { word: words[word_index], songs: [song.num] };
			}
		}

		// min/max release date
		if( released_min == null || song.release < released_min )	released_min = song.release;
		if( released_max == null || song.release > released_max )	released_max = song.release;

		// min/max duration
		if( duration_min == null || song.duration < duration_min )	duration_min = song.duration;
		if( duration_max == null || song.duration > duration_max )	duration_max = song.duration;

		// available languages
		for( var language_index = 0; language_index < song.languages.length; language_index++ ) {
			available_languages[song.languages[language_index]] = true;
		}

		songs_processed++;
		if( (songs_processed % songs_per_transaction) == 0 ) {
			_report_progress( container, ( songs_processed / total_songs ) );
		}
	})
	.then(function() {
		console.log( '[offline/index] successfully parsed the songs and got %d words', Object.keys(index).length );
		var index_array = [];
		for( var key in index ) {
			index_array.push( index[key] );
		}

		return db.songs_index.bulkAdd( index_array ).then(function() {
			console.log( '[offline/index] bulkAdd successfull' );

			console.log('[offline/index] got these stats while parsing: ', {
				// default and allowed values:
				released_min: released_min,
				released_max: released_max,
				duration_min: duration_min,
				duration_max: duration_max,
				languages: Object.keys( available_languages ).sort(),
			});

			return {
				// default and allowed values:
				released_min: released_min,
				released_max: released_max,
				duration_min: duration_min,
				duration_max: duration_max,
				languages: Object.keys( available_languages ).sort(),
			};
		});
	});
}

function _create_artists( db, container, total_songs, callback ) {
	var artists = {};
	var songs_processed = 0;

	return db.songs.orderBy('num').each(function(song) {
		for( var artist_index = 0; artist_index < song.artists.length; artist_index++ ) {
			if( artists[song.artists[artist_index]] ) {
				// increment the existing artists counters
				if( song.available ) {
					artists[song.artists[artist_index]]['songs_available'] += 1;
				} else {
					artists[song.artists[artist_index]]['songs_unavailable'] += 1;
				}
			} else {
				artists[song.artists[artist_index]] = { name: song.artists[artist_index], songs_available: (song.available)?1:0, songs_unavailable: (song.available)?0:1 };
			}
		}

		songs_processed++;
		if( (songs_processed % songs_per_transaction) == 0 ) {
			_report_progress( container, ( songs_processed / total_songs ) );
		}
	})
	.then(function() {
		console.log( '[offline/artists] successfully parsed the songs and got %d artists', Object.keys(artists).length );
		var artists_array = [];
		for( var key in artists ) {
			artists_array.push( artists[key] );
		}

		return db.artists.bulkAdd( artists_array ).then(function() {
			console.log( '[offline/artists] bulkAdd successfull' );
		});
	});
}

function _switch_offline_mode( container, username, from_offline_mode, to_offline_mode ) {
	console.log( '[offline/setup] trying to switch offline_mode from "%s" to "%s" for user %s', from_offline_mode, to_offline_mode, username );

	var from_offline_mode_formatted = valid_offline_modes[from_offline_mode];
	var to_offline_mode_formatted = valid_offline_modes[to_offline_mode];

	if( !from_offline_mode_formatted || !to_offline_mode )
		return Dexie.Promise.reject(new Error('Invalid offline mode to switch from or to.'));

	_show_progress( container, 'Switching from "' + from_offline_mode_formatted + '" to "' + to_offline_mode_formatted + '"' );

	return getDexie.then(function( db ) {
		switch( to_offline_mode ) {
			case 'disabled': {
				_show_progress( container, 'Removing all artists...' );
				// clear artists
				return db.artists.clear().then(function() {
					_report_progress( container, 1/4 );
					_show_progress( container, 'Removing search index...' );

					// clear index
					return db.songs_index.clear();
				}).then(function() {
					// degraded to 'basic'
					if( from_offline_mode == 'full' ) {
						window.localStorage.setItem( 'offline_mode.'+username, 'basic' );
					}

					_report_progress( container, 2/4 );
					_show_progress( container, 'Removing playlists...' );

					// clear playlists
					return db.playlists.clear();
				}).then(function() {
					_report_progress( container, 3/4 );
					_show_progress( container, 'Removing songs...' );

					// clear songs
					return db.songs.clear();
				}).then(function() {
					// if everything is good up to this point, that means that we're all clear
					window.localStorage.setItem( 'offline_mode.'+username, to_offline_mode );

					window.localStorage.removeItem( 'offline_release.'+username );
					window.localStorage.removeItem( 'offline_version.'+username );
					window.localStorage.removeItem( 'last_version.'+username );
					window.localStorage.removeItem( 'last_version_check.'+username );

					window.localStorage.removeItem( 'offline_filters.' + username );

					_report_progress( container, 4/4 );
					_done_progress( container, 'All offline data removed! You\'re now in "' + to_offline_mode_formatted + '" mode.' );
				});
				break;
			}
			case 'basic': {
				if( from_offline_mode == 'full' || from_offline_mode == 'forced' ) {
					// clear artists
					_show_progress( container, 'Removing all artists...' );

					return db.artists.clear().then(function() {
						_report_progress( container, 1/2 );
						_show_progress( container, 'Removing search index...' );

						// clear index
						return db.songs_index.clear();
					}).then(function() {
						// we're on 'basic'
						window.localStorage.setItem( 'offline_mode.'+username, 'basic' );

						window.localStorage.removeItem( 'offline_filters.' + username );

						_report_progress( container, 2/2 );
						_done_progress( container, 'Artists and search index removed! You\'re now in "' + to_offline_mode_formatted + '" mode.' );
					});
				}
				if( from_offline_mode == 'disabled' ) {
					_show_progress( container, 'Clearing playlists (just in case)...' );

					return db.playlists.clear().then(function( ) {
						_show_progress( container, 'Clearing songs (just in case)...' );

						return db.songs.clear();
					}).then(function( ) {
						_show_progress( container, 'Fetching device id...' );

						return _get_device_id()
					}).then(function( device_id ) {
						_show_progress( container, 'Requesting data to download...' );

						return _check_updates( db, device_id ).then(function( stats ) {
							_show_progress( container, 'Downloading songs...' );

							// download songs
							return _download_all_songs( db, container, username, stats.songs.count ).then(function() {
								// download playlists
								_report_progress( container, 0 );
								_show_progress( container, 'Downloading playlists...' );
								return _download_playlists( db, container, device_id, [] );
							});
						});
					}).then(function() {
						window.localStorage.setItem( 'offline_mode.'+username, 'basic' );

						_report_progress( container, 1/1 );
						_done_progress( container, 'Songs and playlists downloaded succesfully! You\'re now in "' + to_offline_mode_formatted + '" mode.' );
					});
				}
				break;
			}
			case 'forced':
			case 'full': {
				if( from_offline_mode == 'disabled' ) {
					_show_progress( container, 'Removing all data (just in case)....' );

					return db.artists.clear().then(function() {
						_report_progress( container, 1/4 );

						// clear index
						return db.songs_index.clear();
					}).then(function() {
						_report_progress( container, 2/4 );

						// clear playlists
						return db.playlists.clear();
					}).then(function() {
						_report_progress( container, 3/4 );

						// clear songs
						return db.songs.clear();
					}).then(function() {
						_report_progress( container, 0 );
						_show_progress( container, 'Fetching device id...' );

						return _get_device_id()
					}).then(function( device_id ) {
						_show_progress( container, 'Requesting data to download...' );

						return _check_updates( db, device_id ).then(function( stats ) {
							// download songs
							_show_progress( container, 'Downloading songs...' );

							return _download_all_songs( db, container, username, stats.songs.count )
							.then(function( ) {
								_report_progress( container, 0 );
								_show_progress( container, 'Fetching device id...' );
								return _get_device_id();
							})
							.then(function( device_id ) {
								// download playlists
								_report_progress( container, 0 );
								_show_progress( container, 'Downloading playlists...' );
								return _download_playlists( db, container, device_id, [] );
							})
							.then(function( ) {
								// create index
								_report_progress( container, 0 );
								_show_progress( container, 'Building search index...' );
								return _create_index( db, container, stats.songs.count );
							}).then(function( index_stats ) {
								// writing the stats
								window.localStorage.setItem( 'offline_filters.' + username, JSON.stringify(index_stats) );

								// create artists
								_report_progress( container, 0 );
								_show_progress( container, 'Parsing artists...' );
								return _create_artists( db, container, stats.songs.count );
							}).then(function( ) {
								window.localStorage.setItem( 'offline_mode.'+username, to_offline_mode );

								// all done!
								_report_progress( container, 1/1 );
								_done_progress( container, 'All done! You\'re now in "' + to_offline_mode_formatted + '" mode.' );
							});
						});
					});
				}
				if( from_offline_mode == 'basic' ) {
					_show_progress( container, 'Removing search index and artists (just in case)....' );

					// clear artists
					return db.artists.clear().then(function() {
						_report_progress( container, 1/2 );

						// clear index
						return db.songs_index.clear();
					}).then( function( ) {
						_report_progress( container, 2/2 );

						return db.songs.count()
					}).then(function( songs_count ) {
						// creating index
						_report_progress( container, 0 );
						_show_progress( container, 'Creating search index...' );

						return _create_index( db, container, songs_count ).then(function( index_stats ) {
							// writing the stats
							window.localStorage.setItem( 'offline_filters.' + username, JSON.stringify(index_stats) );

							// create artists
							_report_progress( container, 0 );
							_show_progress( container, 'Parsing artists...' );
							return _create_artists( db, container, songs_count );
						}).then(function( ) {
							window.localStorage.setItem( 'offline_mode.'+username, to_offline_mode );

							// all done!
							_report_progress( container, 1/1 );
							_done_progress( container, 'Search index and artists are ready! You\'re now in "' + to_offline_mode_formatted + '" mode.' );
						});
					});
				}
				if( from_offline_mode == 'full' || from_offline_mode == 'forced' ) {
					// just need to set the value for offline_mode
					window.localStorage.setItem( 'offline_mode.'+username, to_offline_mode );

					_report_progress( container, 1/1 );
					_done_progress( container, 'All done! You\'re now in "' + to_offline_mode_formatted + '" mode.' );
				}
				break;
			}
		}
	}).then(function() {
		console.log( '[offline] succesfully switched mode to "%s"', to_offline_mode );

		return to_offline_mode;
	});
}

function _determine_local_changes( db ) {
	return db.playlists.toArray().then(function(playlists) {
		var playlist_changes = [];
		var changes_count = 0;
		// TODO: map?
		playlists.forEach(function(playlist) {
			// if playlist was deleted on the client, we should send this info to the server
			if( playlist.client_deleted ) {
				playlist_changes.push({
					id: playlist.id,
					modified: playlist.modified,
					client_deleted: playlist.client_deleted,
				});

				changes_count++;

				return;
			}

			// don't forget to add client_created if playlist was created on the client
			if( playlist.client_created ) {
				// getting playlist changes
				var changed_songs = [];

				playlist.songs.forEach(function(song) {
					if( song.client_added ) {
						changed_songs.push( song );
					}
				});

				playlist_changes.push({
					id: playlist.id,
					title: playlist.title,
					modified: playlist.modified,
					client_created: playlist.client_created,

					changed_songs: changed_songs,
				});

				changes_count++;

				return;
			}

			// getting playlist changes
			var changed_songs = [];

			playlist.songs.forEach(function(song) {
				if( song.client_added || song.client_deleted ) {
					changed_songs.push( song );
				}
			});

			if( changed_songs.length )
				changes_count++;

			playlist_changes.push({
				id: playlist.id,
				title: playlist.title,
				modified: playlist.modified,

				changed_songs: changed_songs,
			});
		});

		return {
			changed_playlists: playlist_changes,
			changes_count: changes_count,
		};
	});
}

function _send_playlist_changes( playlist_changes, device_id, t1c ) {
	return ajaxPromise({
		url: '/offline/sync?type=sync',
		method: 'POST',
		dataType: 'json',
		data: {
			device_id: device_id,
			t1c: t1c,
			t2c: ( Date.now() / 1000 ) | 0,
			//current_playlists: JSON.stringify( playlists ),
			client_changes: JSON.stringify( playlist_changes ),
		},
	})
	.then(function( data ) {
		console.log( '[offline/sync] got that from server: ', data );

		return data.server_changes;
	});
}

function _apply_and_download_changes( db, container, device_id, server_changes ) {
	var download_playlists = [];
	var delete_playlists = [];

	var client_changes = {
		'deleted': 0,
		'created': 0,
		'updated': 0,
	};

	if( !server_changes.length ) {
		console.log( 'no need for playlist update (no changes reported by server)' );
		_show_progress( container, 'No changes received from server.' );
		return client_changes;
	}

	// create an array of playlist ids flagged as changed on the server-side
	server_changes.forEach(function( changed_playlist ) {
		// playlist created on the server
		if( changed_playlist.created ) {
			// playlist was created using the data we've just sent to the server 
			if( changed_playlist.client_id != 0 ) {
				delete_playlists.push( changed_playlist.client_id );
			}
			client_changes['created']++;
		} else if( changed_playlist.modified ) {
			// playlist was modified on the server
			client_changes['updated']++;
		}

		if( changed_playlist.modified || changed_playlist.created ) {
			download_playlists.push( changed_playlist.id );
		}
		if( changed_playlist.deleted ) {
			delete_playlists.push( changed_playlist.id );
			client_changes['deleted']++;
		}
	});

	if( delete_playlists.length ) {
		_show_progress( container, 'Removing playlists deleted on the server...' );
	}

	return db.playlists.bulkDelete( delete_playlists ).then(function() {
		if( download_playlists.length ) {
			_show_progress( container, 'Downloading newly created and changed playlists from the server...' );
			return _download_playlists( db, container, device_id, download_playlists ).then(function( ) {
				return client_changes;
			});
		} else {
			return client_changes;
		}
	});
}

function _sync_playlists( container, username ) {
	var t1c = window.localStorage.getItem('t1c');

	_show_progress( container, 'Fetching device id...' );

	return getDexie.then(function( db ) {
		return _get_device_id().then(function(device_id) {
			return _determine_local_changes( db ).then(function( local_changes ) {
				_show_progress( container, 'Checking with server for updates...' );
				return _check_updates( db, device_id ).then(function( server_stats ) {
					if( local_changes.changes_count || server_stats.playlists.update ) {
						console.log( 'update is needed' );
						_show_progress( container, 'Sending local changes to the server...' );

						return _send_playlist_changes( local_changes.changed_playlists, device_id, t1c ).then(function(server_changes) {
							return _apply_and_download_changes( db, container, device_id, server_changes ).then(function(resulting_changes) {
								_report_progress( container, 1 );
								_done_progress( container, 'Success! Removed ' + resulting_changes['deleted'] + ', created ' + resulting_changes['created'] + ' and updated ' + resulting_changes['updated'] + ' playlists.');
							});
						});
					} else {
						console.log( 'update is not needed (check)' );
						_report_progress( container, 1 );
						_done_progress( container, 'Update is not needed!' );
					}
				});
			});
		});
	});
}

// https://github.com/matthew-andrews/workshop-making-it-work-offline/tree/master/05-offline-news/04-more-hacking-appcache

var cookie_name1 = 'delete_appcache';
var cookie_name2 = 'download_appcache';
var statuses = {
	"-1": 'timeout',
	"0": 'uncached',
	"1": 'idle',
	"2": 'checking',
	"3": 'downloading',
	"4": 'updateready',
	"5": 'obsolete'
};

function _onMessage(event) {
	console.log('[appcache] got the event: ', event);
	if (event.data && event.data.type && event.data.type === 'appcache:event') {
		_onEvent.apply(window, event.data.args || []);
	}
}

function _load_appcache() {
	console.log('[appcache] loading appcache');
	window.addEventListener("message", _onMessage, false);

	var cookieExpires = new Date(new Date().getTime() + 60 * 5 * 1000);
	document.cookie = cookie_name2 + "=1;path=/;expires=" + cookieExpires.toGMTString();

	var iframe = document.createElement('IFRAME');
	iframe.setAttribute('style', 'width:0px; height:0px; visibility:hidden; position:absolute; border:none;');
	iframe.setAttribute('src', '/iframe.html');
	iframe.setAttribute('id', 'appcache');
	document.body.appendChild(iframe);
}

function _delete_appcache() {
	console.log('[appcache] removing appcache');
	window.addEventListener("message", _onMessage, false);

	var cookieExpires = new Date(new Date().getTime() + 60 * 5 * 1000);
	document.cookie = cookie_name1 + "=1;path=/;expires=" + cookieExpires.toGMTString();

	var iframe = document.createElement('IFRAME');
	iframe.setAttribute('style', 'width:0px; height:0px; visibility:hidden; position:absolute; border:none;');
	iframe.setAttribute('src', '/iframe.html');
	iframe.setAttribute('id', 'appcache');
	document.body.appendChild(iframe);
}

function _onEvent(eventCode, hasChecked) {
	var s = statuses[eventCode], loaderEl, cookieExpires;
	console.log( '[appcache] got the event "%s"', s, hasChecked );
	if ( ( hasChecked && ( s === 'uncached' ) ) || s === 'idle' || s === 'obsolete' || s === 'timeout' || s === 'updateready' ) {
		console.log('[appcache] cookie should be removed now');
		loaderEl = document.getElementById('appcache');
		loaderEl.parentNode.removeChild(loaderEl);

		// Remove download/delete cookies
		cookieExpires = new Date(new Date().getTime() - 60 * 5 * 1000);
		document.cookie = cookie_name1 + "=;path=/;expires=" + cookieExpires.toGMTString();
		document.cookie = cookie_name2 + "=;path=/;expires=" + cookieExpires.toGMTString();

		// Remove message listener
		window.removeEventListener("message", _onMessage);
	}
}

var current_offline_mode;
var selected_offline_mode;

module.exports = function( model, container, route ) {
	container = $(container);

	var username = window.localStorage.getItem( 'username' );
	current_offline_mode = window.localStorage.getItem( 'offline_mode.' + username ) || 'disabled';
	container.find('input[name="offline_mode"]').each(function() {
		$(this).prop( 'checked', ( $(this).val() == current_offline_mode ) )
	});
	selected_offline_mode = current_offline_mode;
	container.find('.form-check').removeClass('disabled');
	container.find('input[name="offline_mode"]').prop('disabled', false);
	if( current_offline_mode != 'disabled' ) {
		container.find('#force_sync_playlists').prop('disabled', false).prop('aria-disabled', false);
	}

	container.find('input[name="offline_mode"]').on('change', function(ev) {
		selected_offline_mode = container.find('input[name="offline_mode"]:checked').val();

		if( current_offline_mode == selected_offline_mode ) {
			container.find('#switch_offline_mode').prop('disabled', true).prop('aria-disabled', true);
		} else {
			container.find('#switch_offline_mode').prop('disabled', false).prop('aria-disabled', false);
		}
	});

	container.find('#switch_offline_mode').on('click', function(ev) {
		container.find('#switch_offline_mode').prop('disabled', true).prop('aria-disabled', true);
		container.find('#force_sync_playlists').prop('disabled', true).prop('aria-disabled', true);
		container.find('input[name="offline_mode"]').prop('disabled', true);
		container.find('.form-check').addClass('disabled');

		_switch_offline_mode( container, username, current_offline_mode, selected_offline_mode ).then(function( new_offline_mode ) {
			current_offline_mode = new_offline_mode;

			// enabling other controls
			container.find('.form-check').removeClass('disabled');
			container.find('input[name="offline_mode"]').prop('disabled', false);
			if( new_offline_mode != 'disabled' ) {
				container.find('#force_sync_playlists').prop('disabled', false).prop('aria-disabled', false);
			}
			// won't enable the button, because there is nothing to switch, current_offline_mode = selected_offline_mode
		}).catch(function( err ) {
			console.error( err );

			_fail_progress( container, 'Unfortunately, the switch failed due to this error: "' + err.message + '". Try refreshing the page and doing the switch again. Sorry!' );
		});
	});

	container.find('#force_sync_playlists').on('click', function(ev) {
		container.find('#switch_offline_mode').prop('disabled', true).prop('aria-disabled', true);
		container.find('#force_sync_playlists').prop('disabled', true).prop('aria-disabled', true);
		container.find('input[name="offline_mode"]').prop('disabled', true);
		container.find('.form-check').addClass('disabled');

		_sync_playlists( container, username ).then(function( ) {
			// enabling all controls
			container.find('.form-check').removeClass('disabled');
			container.find('input[name="offline_mode"]').prop('disabled', false);
			container.find('#force_sync_playlists').prop('disabled', false).prop('aria-disabled', false);
			if( current_offline_mode != selected_offline_mode ) {
				container.find('#switch_offline_mode').prop('disabled', false).prop('aria-disabled', false);
			}
		}).catch(function( err ) {
			console.error( err );

			_fail_progress( container, 'Unfortunately, the switch failed due to this error: "' + err.message + '". Try refreshing the page and doing the switch again. Sorry!' );
		});
	});

	container.find('#download_appcache').on('click', function(ev) {
		_load_appcache();
	});

	container.find('#delete_appcache').on('click', function(ev) {
		_delete_appcache();
	});

};