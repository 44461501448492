'use strict'

// server/views/user/email_confirmation.js

var functions = require('../../../lib/functions.client.js');

var tmpl = {
	email_confirmation: require('../../templates/user/email_confirmation'),
	navbar: require('../../templates/navbar'),
	footer: require('../../templates/footer'),
};

module.exports = function email_confirmation( model ) {
	model.menu_items = functions.getMenuItems( model.viewer, model.route.action );
	model.messages = functions.getMessagesArray( model.messages );

	return tmpl.navbar( model ) + '<main>' + tmpl.email_confirmation( model ) + '</main>' + tmpl.footer( model );
}