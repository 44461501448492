'use strict';

// client/controllers/user/playlist/songs.js

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var toastr = (typeof window !== "undefined" ? window['toastr'] : typeof global !== "undefined" ? global['toastr'] : null);

var functions = require('../../../../lib/functions.client.js');
//var throttle = require('lodash/throttle');
var debounce = require('lodash/debounce');

module.exports = function( model, container, route ) {
	functions.animateSongList( container );

	// clear and delete list actions confirmation
	if( model.viewer.is_owner ) {
		$(container).find('.playlist-clear-button').each(function() {
			var that = $(this);
			that.webuiPopover({
				placement: 'auto-bottom',
				trigger: 'click',
				animation: 'pop',
				cache: false,
				title: 'Are you sure you want to clear this playlist?',
				content: 
					$(	'<form action="/playlist/modify" method="post">' +
							'<div class="text-xs-right">' + 
								'<input type="hidden" name="_csrf" value="' + model._csrf + '">' +
								'<button class="btn btn-warning" type="submit" name="list[clear][i' + model.list_id + ']" value="1">Clear</button>' +
								'&nbsp;' +
								'<button class="btn btn-secondary close-popup" type="button">Close</button>' +
							'</div>' +
						'</form>'
					).on( 'submit', function(ev) {
						ev.preventDefault();

						functions.doPlaylistAction( '/playlist/modify', [
							{ name: '_csrf', value: model._csrf },
							{ name: 'list[clear][i' + model.list_id + ']', value: 1 },
						])
						.done(function( data, textStatus, jqXHR ) {
							that.webuiPopover('hide');

							// refresh the same page
							taunus.navigate( route.url, { 'force': true } );
						})
						.fail(function( jqXHR, textStatus, errorThrown ) {
							console.error( 'error while clearing playlist: ', errorThrown );
						});
					}),
				dismissible: false,
				autoHide: 5000,
				width: 250,
				onShow: function( $el ) {
					$el.find('.close-popup').on('click', function( ev ) {
						that.webuiPopover('hide');
					});
					that.prop( 'disabled', true );
				},
				onHide: function( $el ) {
					$el.find('.close-popup').off('click');
					that.prop( 'disabled', false );
				},
			});
		});

		$(container).find('.playlist-delete-button').each(function() {
			var that = $(this);
			that.webuiPopover({
				placement: 'auto-bottom',
				trigger: 'click',
				animation: 'pop',
				cache: false,
				title: 'Are you sure you want to delete this playlist?',
				content: 
					$(	'<form action="/playlist/modify" method="post">' +
							'<div class="text-xs-right">' +
								'<input type="hidden" name="_csrf" value="' + model._csrf + '">' +
								'<button class="btn btn-danger" type="submit" name="list[delete][i' + model.list_id + ']" value="1">Delete</button>' +
								'&nbsp;' +
								'<button class="btn btn-secondary close-popup" type="button">Close</button>' +
							'</div>' + 
						'</form>'
					).on( 'submit', function(ev) {
						ev.preventDefault();

						functions.doPlaylistAction( '/playlist/modify', [
							{ name: '_csrf', value: model._csrf },
							{ name: 'list[delete][i' + model.list_id + ']', value: 1 },
						])
						.done(function( data, textStatus, jqXHR ) {
							that.webuiPopover('hide');

							// relying on owner.username is probably not the best way to do this
							taunus.navigate( taunus.resolve('user/profile', { username: model.owner.username }), { 'force': true } );
						})
						.fail(function( jqXHR, textStatus, errorThrown ) {
							console.error( 'error while deleting playlist: ', errorThrown );
						});
					}),
				dismissible: false,
				autoHide: 5000,
				width: 250,
				onShow: function( $el ) {
					$el.find('.close-popup').on('click', function( ev ) {
						that.webuiPopover('hide');
					});
					that.prop( 'disabled', true );
				},
				onHide: function( $el ) {
					$el.find('.close-popup').off('click');
					that.prop( 'disabled', false );
				},
			});
		});
	}

	$(container).find('.duration-popup').each(function() {
		$(this).webuiPopover({
			placement: 'auto-bottom',
			trigger: 'hover',
			delay: { 'show': 300, 'hide': 300 },
			animation: null,
			cache: true,
			title: 'Playlist duration',
			content: '<p class="m-b-0">Calculated based on songs available in your release.</p>',
			width: 250,
		});
	});

	if( 'viewer' in model )
		if( model.viewer.username )
			window.localStorage.setItem('username', model.viewer.username );

	var list = document.querySelector('#song-accordion.reorder');
	if( list ) {
		new Slip(list);

		list.addEventListener('slip:beforeswipe', function(e) {
			e.preventDefault(); // no swiping
		});

		list.addEventListener('slip:reorder', function(e) {
			var playlistId = parseInt( list.getAttribute('data-playlist-id'), 10 ) || 0;
			var startingPosition = parseInt( list.getAttribute('data-playlist-starting-position'), 10 ) || 0;
			var csrf_code = list.getAttribute('data-csrf');
			console.log( 'playlist #%d with starting position: %d', playlistId, startingPosition );

			var oldPosition = startingPosition + e.detail.originalIndex;
			var newPosition = startingPosition + e.detail.spliceIndex;
			console.log( [ oldPosition, newPosition ] );

			var data = {
				'oldposition': oldPosition,
				'newposition': newPosition,
				'_csrf': csrf_code,
			};
			data['list[change_position][i'+playlistId+']'] = 1;

			// TODO: should we insert a real number? or should we just remove it entirely?
			$.ajax({
				url: taunus.resolve('user/playlist/modify', {}),
				data: data,
				method: 'POST',
				dataType: 'json',
			})
			.done( function( data, textStatus, jqXHR ) {
				e.target.parentNode.insertBefore( e.target, e.detail.insertBefore );

				var message;

				// TODO: react to valid server response, but invalid data content
				if( data.action == 'playlist/change_position' ) {
					// added succesfully
					message = 'Song\'s position have changed.';
				}

				// TODO: diferentiate between add/remove/change_position?
				toastr.success( message );
			})
			.fail(function( jqXHR, textStatus, errorThrown ) {
				e.preventDefault();

				console.error( 'error while changing position of a song in a playlist: ', errorThrown );

				// TODO: server-returned error should be shown if status is ok
				toastr.error( 'Error while changing song\'a position.' );
			});
		}, false);

		// reordering start immediately if we're dragging on handle
		list.addEventListener('slip:beforewait', function(e) {
			if (e.target.className.indexOf('fa-sort') > -1) {
				e.preventDefault();
			}
		}, false);

		// allow reorder only by dragging on handle
		list.addEventListener('slip:beforereorder', function(e) {
			if (e.target.className.indexOf('fa-sort') == -1) {
				e.preventDefault();
			} else if( Offline.state != 'up' ) {
				e.preventDefault();
			}
		}, false);
	}

	// detect scrolling
	var $window = $(window);

	function getDocHeight() {
		var D = document;
		return Math.max(
			D.body.scrollHeight, D.documentElement.scrollHeight,
			D.body.offsetHeight, D.documentElement.offsetHeight,
			D.body.clientHeight, D.documentElement.clientHeight
		);
	}

	$window.on('scroll', debounce(function() {
		if( $window.scrollTop() + $window.height() > getDocHeight() - 100 ) {
			$window.unbind('scroll');
			console.log("near bottom!");

			// load and bind again
			// or do nothing until user clicks a button
		}
	}, 500) );

	return false;
};
